import {
  Box,
  Color,
  Display,
  AlignItems,
  JustifyContent,
  Heading6,
  TextAlign,
  Text,
  withCitrusTheme,
  Bold,
} from '@gousto-internal/citrus-react'
import React from 'react'
import { useOrderTileContext } from '../hooks/useOrderTileContext'

const Strikethrough = withCitrusTheme(Text, () => ({
  textDecoration: 'line-through',
}))

export const DiscountPrice = () => {
  const context = useOrderTileContext()

  if (!context.price.priceWithDiscount) {
    return (
      <Text size={7} display={Display.Inline}>
        <Bold>£{context.price.priceWithoutDiscount}</Bold>
      </Text>
    )
  }

  return (
    <Text textAlign={TextAlign.Right} data-testid="discount-price">
      <Strikethrough size={[2, 6]} display={Display.Inline}>
        £{context.price.priceWithoutDiscount}
      </Strikethrough>
      <br />
      <Text size={[3, 7]} display={Display.Inline}>
        <Bold>£{context.price.priceWithDiscount}</Bold>
      </Text>
    </Text>
  )
}

export const DiscountPercentage = () => {
  const context = useOrderTileContext()

  if (!context.orderDiscount) {
    return null
  }

  return (
    <Box
      width="60px"
      height="60px"
      bg={Color.Success_400}
      borderRadius="round"
      display={Display.Flex}
      alignItems={AlignItems.Center}
      justifyContent={JustifyContent.Center}
      data-testid="discount-badge"
    >
      <Heading6 textAlign={TextAlign.Center} color={Color.White}>
        {context.orderDiscount}
        <br />
        off
      </Heading6>
    </Box>
  )
}

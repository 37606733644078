import { useContext } from 'react'

import { OptimizelyContext } from '@optimizely/react-sdk'
import { useSelector } from 'react-redux'

import { canUseWindow } from '@library/environment'

import { PROMO_CODES_MAPPED_TO_EXPERIMENTS } from 'routes/Home/homeConfig'
import { getIsAuthenticated } from 'selectors/auth'
import { getPromoCode } from 'selectors/basket'
import Cookies from 'utils/GoustoCookies'
import logger from 'utils/logger'

import { useGetDiscountSofteningFreeDeliveryFromPromoCode } from './useGetDiscountSofteningFreeDeliveryFromPromoCode'

export type DiscountSofteningFreeDeliveryTreatments = 't1' | 't2' | 't3'

const OFX_EXPERIMENT_KEY = 'beetroots_ofx_discount_softening_free_delivery_v2'

function isTreatmentKey(key: unknown): key is DiscountSofteningFreeDeliveryTreatments {
  return typeof key === 'string' && ['t1', 't2', 't3'].includes(key)
}

export function hasConsentForAnalytics(): boolean {
  try {
    const consentCookieString = Cookies.get('v1_gousto_cookie_consent')
    const consentCookie = consentCookieString ? JSON.parse(consentCookieString) : {}

    return consentCookie?.categories?.includes('ANALYTICS') || false
  } catch (error) {
    if (error instanceof Error) {
      ;(logger as any).error(`Error parsing consent cookie: ${error.message}`)
    }

    return false
  }
}

const hasMappedExperimentPromoCode = (promoCode: string, experimentKey: string): boolean => {
  const experimentMapping = PROMO_CODES_MAPPED_TO_EXPERIMENTS[promoCode]

  return experimentMapping?.includes(experimentKey) ?? false
}

function hasUrlPromoCodeExcludedFromExperiment(): boolean {
  if (!canUseWindow()) {
    return false
  }

  const params = new URLSearchParams(window?.location?.search)
  const urlPromoCode = params.get('promo_code')

  if (urlPromoCode !== null) {
    return !hasMappedExperimentPromoCode(urlPromoCode, OFX_EXPERIMENT_KEY)
  }

  return false // no promo code in the URL
}

export function useGetDiscountSofteningFreeDelivery():
  | [true, DiscountSofteningFreeDeliveryTreatments]
  | [false, undefined] {
  const isAuthenticated = useSelector(getIsAuthenticated)
  const { optimizely } = useContext(OptimizelyContext)
  const promoCode = useSelector(getPromoCode)
  const discountSofteningFreeDeliveryFromPromoCode =
    useGetDiscountSofteningFreeDeliveryFromPromoCode()

  // If we can determine the treatment from the promo code, we exit early
  if (isTreatmentKey(discountSofteningFreeDeliveryFromPromoCode)) {
    return [
      true,
      discountSofteningFreeDeliveryFromPromoCode as DiscountSofteningFreeDeliveryTreatments,
    ]
  }

  const hasNoPromoCodeStatus = !promoCode
  const hasUrlPromoCodeStatusAllowingExperiment = !hasUrlPromoCodeExcludedFromExperiment()
  const hasConsentForAnalyticsStatus = hasConsentForAnalytics()
  const hasCorrectAuthStatus = !isAuthenticated
  const isEligibleForExperiment =
    optimizely &&
    hasConsentForAnalyticsStatus &&
    hasUrlPromoCodeStatusAllowingExperiment &&
    hasCorrectAuthStatus &&
    hasNoPromoCodeStatus

  const { enabled, variationKey } = isEligibleForExperiment
    ? (() => optimizely.decide(OFX_EXPERIMENT_KEY))()
    : (() => ({ enabled: false, variationKey: undefined }))()

  if (enabled && isTreatmentKey(variationKey)) {
    return [true, variationKey]
  } else {
    return [false, undefined]
  }
}

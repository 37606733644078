import React from 'react'

import { Body2, Box, Heading4, Space } from '@gousto-internal/citrus-react'

export function ExpressCheckoutHeader() {
  return (
    <Box>
      <Heading4>Express Checkout</Heading4>
      <Space size={2} />
      <Body2>We’ll get your delivery details from your wallet</Body2>
      <Space size={4} />
    </Box>
  )
}

import { MenuRecipe } from '@library/menu-service'
import { menuRecipeModel } from '../models/menuRecipeModal'
type ImageDetails = {
  recipeId: string
  url: string
  width: number
  alt: string
}

export const useChosenForYouTile = (recipes: MenuRecipe[]) => {
  if (recipes.length === 0) {
    return []
  }
  const imageDetails: ImageDetails[] = []
  for (const recipe of recipes) {
    const image = menuRecipeModel().getImage(recipe)
    if (!image) {
      return []
    }
    const crop = menuRecipeModel().getCrop(image)
    if (!crop) {
      return []
    }
    imageDetails.push({
      recipeId: recipe.id,
      url: crop.url,
      width: crop.width,
      alt: recipe.name,
    })
  }
  return imageDetails
}

import { Order } from '@library/api-user'
import { queueTrackingEvent } from '@library/tracking-integration'
import { orderModel } from '../models/orderModel'

type CommonDetails = {
  order_id: string
}

type TrackingOrderDetails = {
  period_id: number
  portion_size: string
  delivery_date: string | undefined
  order_state: string
} & CommonDetails

type TrackingRecommendedRecipes = {
  recipe_id: string
}

type TrackingEventsForOrderDetails = {
  action:
    | 'choose_recipes_click'
    | 'plates_click'
    | 'restore_order_click'
    | 'cancel_order_click'
    | 'click_edit_recipes'
    | 'order_expand_click'
  properties: TrackingOrderDetails
}

type TrackingEventsForGetHelp = {
  action: 'click_get_help_with_this_box'
  properties: CommonDetails
}

type TrackingEventsForRecommendedRecipe = {
  action: 'recommended_recipe_click'
  properties: TrackingRecommendedRecipes
}

const getTrackingPayload = (order: Order, model: ReturnType<typeof orderModel>) => {
  return {
    order_id: model.getOrderId(order),
    period_id: model.getOrderPeriod(order).id,
    portion_size: model.getOrderNumberOfPortions(order),
    delivery_date: model.getDeliveryDate(order),
    order_state: model.getOrderState(order),
  }
}

export type UseOrderTracking = {
  trackChooseRecipes: () => void
  trackPlates: () => void
  trackEditRecipes: () => void
  trackRestoreOrder: () => void
  trackCancelOrder: () => void
  trackGetHelp: () => void
  trackRecommendedRecipe: (params: TrackingRecommendedRecipes) => void
  trackExpandOrder: () => void
}

export const useOrderTracking = (order: Order): UseOrderTracking => {
  const model = orderModel()

  return {
    trackCancelOrder: () => {
      const payload = getTrackingPayload(order, model)

      queueTrackingEvent<TrackingEventsForOrderDetails>({
        action: 'cancel_order_click',
        properties: payload,
      })
    },
    trackChooseRecipes: () => {
      const payload = getTrackingPayload(order, model)

      queueTrackingEvent<TrackingEventsForOrderDetails>({
        action: 'choose_recipes_click',
        properties: payload,
      })
    },
    trackPlates: () => {
      const payload = getTrackingPayload(order, model)

      queueTrackingEvent<TrackingEventsForOrderDetails>({
        action: 'plates_click',
        properties: payload,
      })
    },
    trackEditRecipes: () => {
      const payload = getTrackingPayload(order, model)

      queueTrackingEvent<TrackingEventsForOrderDetails>({
        action: 'click_edit_recipes',
        properties: payload,
      })
    },
    trackGetHelp: () => {
      queueTrackingEvent<TrackingEventsForGetHelp>({
        action: 'click_get_help_with_this_box',
        properties: {
          order_id: model.getOrderId(order),
        },
      })
    },
    trackRestoreOrder: () => {
      const payload = getTrackingPayload(order, model)

      queueTrackingEvent<TrackingEventsForOrderDetails>({
        action: 'restore_order_click',
        properties: payload,
      })
    },
    trackRecommendedRecipe: ({ recipe_id }: TrackingRecommendedRecipes) => {
      queueTrackingEvent<TrackingEventsForRecommendedRecipe>({
        action: 'recommended_recipe_click',
        properties: {
          portion_size: model.getOrderNumberOfPortions(order),
          order_id: model.getOrderId(order),
          recipe_id,
        },
      })
    },
    trackExpandOrder: () => {
      const payload = getTrackingPayload(order, model)

      queueTrackingEvent<TrackingEventsForOrderDetails>({
        action: 'order_expand_click',
        properties: payload,
      })
    },
  }
}

import type { ParsedProcessEnv } from './processEnv'

/**
 * Window location
 * ============================================================================
 */

export type Protocol = 'http:' | 'https:'

/**
 * Caters for the edge-case of directly accessing webclient/frontend via the ALB
 */
type HostNames = 'webclient' | 'frontend'

type SubDomain = 'www' | `${string}-www` | `${string}-${HostNames}` | 'frontend'

type RootDomain = 'gousto' | `${string}.gousto`

type TopLevelDomain = `.local:${number}` | `.local` | '.info' | '.co.uk'

export type Domain = `${RootDomain}${TopLevelDomain}`

export type WindowLocation = {
  host: `${SubDomain}.${RootDomain}${TopLevelDomain}`
  protocol: Protocol
}

/**
 * Browser config
 * ============================================================================
 */

export type WindowEnvConfig = Pick<
  ParsedProcessEnv,
  | 'RECAPTCHA_PUBK'
  | 'GOOGLE_PAY_MERCHANT_ID'
  | 'CHECKOUT_COM_PUBK'
  | 'DATADOG_RUM_SDK_APP_ID'
  | 'DATADOG_RUM_SDK_CLIENT_TOKEN'
  | 'DATADOG_BROWSER_LOGS_CLIENT_TOKEN'
  | 'ENVIRONMENT'
  | 'DOMAIN'
  | 'APPLE_PAY_MERCHANT_ID'
  | 'ASSETS_DOMAIN'
>

/**
 * Service environment
 * ============================================================================
 */

export enum PROTOCOL_PREFIX {
  HTTP = 'http:',
  HTTPS = 'https:',
}

export enum ENVIRONMENT_NAMES {
  production = 'production',
  staging = 'staging',
  local = 'local',
}

declare global {
  // eslint-disable-next-line
  interface Window {
    __config__: WindowEnvConfig
  }
}

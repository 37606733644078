import React, { useCallback } from 'react'
import {
  Box,
  BorderStyle,
  Color,
  AspectRatio,
  Image,
  ImageProps,
  GridDisplay,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'
import { useRecipeImageTile } from '../hooks/useRecipeImageTile'

import EmptyPlateJPG from '../assets/emptyPlate.png'
import { browserHistory } from 'react-router'
import { useOrderTileContext } from '../hooks/useOrderTileContext'
import { useTrackingContext } from '../hooks/useTrackingContext'

const ClickableBox = withCitrusTheme(Box, () => ({
  cursor: 'pointer',
}))

const StyledImage = withCitrusTheme(Image, () => ({
  filter: 'drop-shadow(10px 17px 11px #00000021)',
  boxShadow:
    '1px 2px 5px 0px #0000001A, 4px 8px 9px 0px #00000017, 10px 17px 12px 0px #0000000D, 18px 31px 14px 0px #00000003, 28px 48px 16px 0px #00000000',
  borderRadius: '50%',
}))

const EmptyRecipeImage = () => (
  <AspectRatio ratio={1 / 1}>
    <Box
      borderStyle={BorderStyle.Dashed}
      borderColor={Color.ColdGrey_200}
      borderWidth={0.5}
      width="100%"
      data-testid={'empty-recipe-image'}
      paddingBottom={[1, 3]}
      paddingH={[1, 3]}
      paddingRight={[1, 3]}
      paddingV={[1, 3]}
    >
      <StyledImage src={EmptyPlateJPG} alt="Add Recipe" />
    </Box>
  </AspectRatio>
)

const RecipeImage = (props: Partial<ImageProps>) => {
  const { src } = props
  const alt = 'Recipe Image'

  return (
    <AspectRatio ratio={1 / 1}>
      <Image src={src} alt={alt} />
    </AspectRatio>
  )
}

RecipeImage.displayName = 'RecipeImage'

export const RecipeImageTiles = () => {
  const context = useOrderTileContext()
  const { trackPlates } = useTrackingContext()
  const { orderId, isOrderEditable } = context
  const { maxRecipes, recipeImageURLs } = useRecipeImageTile(context.recipeItems, context.box)

  const handleClick = useCallback(() => {
    if (!isOrderEditable) {
      return
    }

    browserHistory.push(`/menu/${orderId}`)
    trackPlates()
  }, [isOrderEditable, trackPlates, orderId])

  return (
    <Box
      width="100%"
      display={GridDisplay.Grid}
      gridTemplateColumns={`repeat(${maxRecipes}, 1fr)`}
      gap={[2, 4]}
    >
      {recipeImageURLs.map((recipeImageUrl, index) => {
        return (
          <ClickableBox key={`${orderId}-recipe-image-${index}`} width="100%" onClick={handleClick}>
            {recipeImageUrl ? <RecipeImage src={recipeImageUrl} /> : <EmptyRecipeImage />}
          </ClickableBox>
        )
      })}
    </Box>
  )
}

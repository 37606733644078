import React from 'react'

import {
  AlignItems,
  BorderStyle,
  Box,
  Color,
  Display,
  FontFamily,
  Icon,
  IconVariant,
  Space,
  Text,
} from '@gousto-internal/citrus-react'

import type { ExpressPaymentType } from '../types'

const ExpressCheckoutFallBack = (paymentType: ExpressPaymentType) => (
  <>
    <Box
      bg={Color.Warning_50}
      display={Display.Flex}
      paddingH={4}
      paddingV={4}
      alignItems={AlignItems.FlexStart}
      borderColor={Color.Warning_100}
      borderWidth={0.5}
      borderStyle={BorderStyle.Solid}
      borderRadius={1.5}
      gap={2}
    >
      <Icon variant={IconVariant.Error} name="report_problem" />
      <Box>
        <Text fontFamily={FontFamily.Bold} size={2}>
          There’s an issue with connecting to {paymentType}
        </Text>
        <Text size={2}>
          Please continue with checkout below and choose an alternative payment method on the next
          step
        </Text>
      </Box>
    </Box>
    <Space size={2} />
  </>
)

export const GooglePayFallBack = () => ExpressCheckoutFallBack('Google Pay')
export const ApplePayFallBack = () => ExpressCheckoutFallBack('Apple Pay')

import React from 'react'

import { AlignItems, Box, Display } from '@gousto-internal/citrus-react'
import classnames from 'classnames'

import { MenuRecipe } from '@library/menu-service/queries/menuApiMenuRecipe/types'

import { RecipeAttributePill } from '../../RecipeAttributePill/RecipeAttributePill'
import { replaceDashWithUnderscore, formatDietaryText, formatShelfLifeText } from './formatters'

import css from './AttributeGrid.css'

type AttributeGridProps = {
  recipe: MenuRecipe
  loading?: boolean
}

const LoadingSkeleton = ({ style }: { style?: React.CSSProperties }) => (
  <span className={css.loadingSkeleton} style={style} data-testid="LoadingSkeleton" />
)

export const RecipeDetailsGrid = ({ recipe, loading }: AttributeGridProps) => {
  const {
    dietary_claims: dietaryClaims,
    equipment: equipments,
    shelf_life: shelfLife,
    five_a_day: fiveADay,
  } = recipe

  if (loading) {
    return (
      <div className={classnames(css.attributes)}>
        {[...Array(4)].map(() => (
          <LoadingSkeleton key={Math.random()} style={{ height: '10px', marginBottom: '10px' }} />
        ))}
      </div>
    )
  }

  if (!dietaryClaims && !shelfLife && !equipments) {
    return null
  }

  return (
    <Box
      alignItems={AlignItems.FlexStart}
      wrap
      data-testid="RecipeAttribute"
      display={Display.Flex}
    >
      {dietaryClaims?.length > 0 &&
        dietaryClaims.map(({ slug, name }) => (
          <RecipeAttributePill
            key={slug}
            icon={replaceDashWithUnderscore(slug)}
            text={formatDietaryText(name)}
          />
        ))}

      {!!fiveADay && <RecipeAttributePill icon="5_a_day" text={`${fiveADay}/5 a day`} />}

      {shelfLife && (
        <RecipeAttributePill icon="calendar" text={formatShelfLifeText(shelfLife?.max_days)} />
      )}

      {equipments?.length > 0 &&
        equipments.map(({ name }) => (
          <RecipeAttributePill key={name} icon="equipment" text={name} />
        ))}
    </Box>
  )
}

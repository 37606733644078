import useSWR, { useSWRConfig } from 'swr'
import { Order, ProductItem, RecipeItem } from '@library/api-user'
import { ApiKeys, LOCALE } from '../configs'
import { OrderDiscountPrice, orderModel } from '../models/orderModel'
import { useMemo, useState } from 'react'
import { restoreCancelledOrder } from '@library/api-order'

export const orderPhaseToHumanReadableMap = {
  cancelled: 'Cancelled',
  choose: 'Menu open',
  edit: 'Recipes chosen',
  packing: 'We are preparing your order',
  confirmed: 'We are preparing your order',
  picking: 'We are preparing your order',
  dispatched: 'Dispatched',
  delivered: 'Delivered',
  preMenu: 'Scheduled',
}

export const formatOrderPhaseToHumanReadable = (
  phaseKey: keyof typeof orderPhaseToHumanReadableMap,
): string => {
  return orderPhaseToHumanReadableMap[phaseKey]
}

export const formatTimestampToTwelveHourTime = (dateString: string): string => {
  return new Date(dateString).toLocaleString(LOCALE, {
    hour12: true,
    timeStyle: 'short',
  })
}

export const formatTimestampToOrdinalDate = (dateString: string): string => {
  const rules = new Intl.PluralRules(LOCALE, { type: 'ordinal' })
  const suffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
  ])

  const day = new Date(dateString).getDate()
  const month = new Date(dateString).toLocaleString(LOCALE, { month: 'long' })
  const rule = rules.select(day)
  const suffix = suffixes.get(rule)

  return `${day}${suffix} ${month}`
}

export interface UseOrderTileResult {
  data: {
    areRecipesChosenForPendingOrder: boolean
    box: {
      numPortions: string
      numRecipes: string
    }
    deliveryDate: string
    daySlotLeadTimeId: string
    humanDeliveryDate: string
    humanDeliveryDayTime: string
    isAwaitingChoices: boolean
    isOrderCancelled: boolean
    isOrderCommitted: boolean
    isOrderDelivered: boolean
    isOrderEditable: boolean
    isOrderOnPreMenu: boolean
    isOrderRestorable: boolean
    isTransactionalOrderCancelled: boolean
    orderDiscount: string | null
    orderId: string
    orderPhaseContent: string
    period: {
      id: number
      start: string
      end: string
    }
    price: OrderDiscountPrice
    productItems: ProductItem[]
    recipeItems: RecipeItem[]
    shippingAddress: Partial<Order['shippingAddress']>
    shouldCutOffTime: string
    shouldCutOffDate: string
    shouldCutOffISODate: string
  }
  isOrderExpanded: boolean
  setIsOrderExpanded: (isExpanded: boolean) => void
  setShouldRestoreOrder: (shouldRestore: boolean) => void
  shouldRestoreOrder: boolean
}

const getOrderTileData = (order: Order) => {
  const model = orderModel()

  return {
    areRecipesChosenForPendingOrder: model.areRecipesChosenForPendingOrder(order),
    box: {
      numPortions: model.getOrderNumberOfPortions(order),
      numRecipes: model.getOrderNumberOfRecipes(order),
    },
    deliveryDate: model.getDeliveryDate(order) as string,
    daySlotLeadTimeId: model.getOrderDeliverySlotLeadTimeId(order),
    humanDeliveryDate: model.getHumanDeliveryDate(order),
    humanDeliveryDayTime: model.getHumanDeliveryDayTime(order),
    isAwaitingChoices: model.isOrderAwaitingChoices(order),
    isOrderCancelled: model.isOrderCancelled(order),
    isOrderCommitted: model.isOrderCommitted(order),
    isOrderDelivered: model.isOrderDelivered(order),
    isOrderEditable: model.isOrderEditable(order),
    isOrderOnPreMenu: model.isOrderOnPreMenu(order),
    isOrderRestorable: model.isOrderRestorable(order),
    isTransactionalOrderCancelled: model.isTransactionalOrderCancelled(order),
    orderId: model.getOrderId(order),
    orderDiscount: model.getDiscountForOrder(order),
    orderPhaseContent: formatOrderPhaseToHumanReadable(
      model.getOrderPhase(order) as keyof typeof orderPhaseToHumanReadableMap,
    ),
    period: model.getOrderPeriod(order),
    price: model.getPriceForOrder(order),
    productItems: model.getProducts(order),
    recipeItems: model.getRecipes(order),
    shippingAddress: model.getOrderShippingAddress(order),
    shouldCutOffDate: formatTimestampToOrdinalDate(model.getShouldCutOffAt(order)),
    shouldCutOffTime: formatTimestampToTwelveHourTime(model.getShouldCutOffAt(order)),
    shouldCutOffISODate: model.getShouldCutOffAt(order),
  }
}

const useOrderTile = (order: Order): UseOrderTileResult => {
  const [isOrderExpanded, setIsOrderExpanded] = useState<boolean>(false)
  const [shouldRestoreOrder, setShouldRestoreOrder] = useState<boolean>(false)

  const { mutate } = useSWRConfig()
  const { data: restoredOrderData, error } = useSWR(
    shouldRestoreOrder ? order.id : null,
    restoreCancelledOrder,
  )

  if (restoredOrderData && !error) {
    mutate(ApiKeys.UserCurrentOrders)
  }

  if (error) {
    setShouldRestoreOrder(false)
  }

  const data = useMemo(() => {
    return getOrderTileData(order)
  }, [order])

  return {
    data,
    isOrderExpanded,
    setIsOrderExpanded,
    setShouldRestoreOrder,
    shouldRestoreOrder,
  }
}

export { useOrderTile }

import { MenuRecipe, MenuRecipeImage } from '@library/menu-service'

type Crop = {
  url: string
  width: number
}

export function menuRecipeModel() {
  const getImage = (recipe: MenuRecipe): MenuRecipeImage =>
    recipe.images.find((image) => image.type === 'mood-image') as MenuRecipeImage

  const getCrop = (image: MenuRecipeImage) => image.crops.find((corp) => corp.width === 200) as Crop

  return {
    getImage,
    getCrop,
  }
}

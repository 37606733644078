import React from 'react'

import {
  AlignItems,
  Box,
  Color,
  Display,
  Divider,
  FlexDirection,
  FontFamily,
  Space,
  Text,
} from '@gousto-internal/citrus-react'

export const ExpressCheckoutDivider = () => (
  <Box display={Display.Flex} alignItems={AlignItems.Center} flexDirection={FlexDirection.Row}>
    <Space size={2} />
    <Box flexGrow={1}>
      <Divider />
    </Box>
    <Box paddingH={4}>
      <Text color={Color.ColdGrey_400} fontFamily={FontFamily.Bold} size={2}>
        or
      </Text>
    </Box>
    <Box flexGrow={1}>
      <Divider />
    </Box>
    <Space size={8} />
  </Box>
)

import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

import { actionTypes } from 'actions/actionTypes'
import statusActions from 'actions/status'
import { trackCheckoutError } from 'actions/tracking'
import {
  saveApplePayCheckoutToken,
  saveApplePayDeliveryAddress,
  saveApplePayPersonalInfo,
  validateShippingContact,
  checkoutSignup,
} from 'routes/Checkout/checkoutActions'
import { hasCheckoutError } from 'routes/Checkout/checkoutSelectors'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'
import { Pricing } from 'routes/Menu/domains/pricing'

/**
 * Completes checkout payment as if payment was authorized.
 * @param event - Apple Pay payment authorized event
 * @param session - Apple Pay session
 * @param pricing - checkout pricing info (total price, etc.)
 * @param applePayInstance - braintree applePayInstance
 */
export const applePayPaymentWithBraintree: CheckoutActionCreator =
  (
    event: ApplePayJS.ApplePayPaymentAuthorizedEvent,
    session: ApplePaySession,
    pricing: Pricing,
    applePayInstance: braintree.ApplePay,
  ) =>
  async (dispatch, getState) => {
    const errors = dispatch(validateShippingContact(event.payment.shippingContact))

    if (errors.length > 0) {
      session.completePayment({
        status: ApplePaySession.STATUS_FAILURE,
        errors,
      })

      return
    }

    dispatch(saveApplePayDeliveryAddress(event.payment.shippingContact))
    dispatch(saveApplePayPersonalInfo(event.payment.shippingContact))

    try {
      const data = await applePayInstance.tokenize({
        token: event.payment.token,
      })
      dispatch(saveApplePayCheckoutToken(data.nonce))
    } catch (e) {
      dispatch(statusActions.error(actionTypes.APPLE_PAY_TOKEN_CONVERSION_ERROR, true))
      datadogLogs.logger.error(`apple pay merchant failed with ${JSON.stringify(e)}`)
      dispatch(
        trackCheckoutError(
          actionTypes.APPLE_PAY_TOKEN_CONVERSION_ERROR,
          true,
          'checkoutApplePayPayment',
        ),
      )
      session.completePayment(ApplePaySession.STATUS_FAILURE)

      return
    }

    await dispatch(checkoutSignup({ pricing }))

    const state = getState()
    const isFinishedWithError = hasCheckoutError(state)

    session.completePayment(
      isFinishedWithError ? ApplePaySession.STATUS_FAILURE : ApplePaySession.STATUS_SUCCESS,
    )

    if (!isFinishedWithError) {
      datadogRum.addAction('apple_pay_payment_was_successful')
    }
  }

import Immutable from 'immutable'

import { actionTypes } from 'actions/actionTypes'
import { PaymentMethod } from 'routes/Signup/signupConfig'

import { CardProvider, PaymentAction } from './models/CheckoutTypes'

export const initialState = () =>
  Immutable.Map({
    challengeUrl: null,
    isModalVisible: false,
    paymentMethod: PaymentMethod.Card,
    provider: CardProvider.BENTO,
    isApplePayBraintree: false,
    paypalClientToken: null,
    cardPaymentToken: null,
    paypalNonce: null,
    paypalDeviceData: null,
    isCardTokenNotCompliantFor3ds: false,
    applePayToken: null,
    googlePayToken: null,
    isBillingAddressDifferent: false,
    billingAddress: Immutable.fromJS({
      postcode: '',
      houseNo: '',
      street: '',
      town: '',
      county: '',
      udprn: '',
    }),
  })

export const payment = (state = initialState(), action: PaymentAction) => {
  switch (action.type) {
    case actionTypes.PAYMENT_SHOW_MODAL: {
      return state.set('isModalVisible', true).set('challengeUrl', action.challengeUrl)
    }

    case actionTypes.PAYMENT_HIDE_MODAL: {
      return state.set('isModalVisible', false).set('challengeUrl', null)
    }

    case actionTypes.PAYMENT_SET_PAYMENT_METHOD: {
      return state.set('paymentMethod', action.paymentMethod).set('paypalNonce', null)
    }

    case actionTypes.PAYMENT_SET_PAYPAL_CLIENT_TOKEN: {
      const { token } = action

      return state.set('paypalClientToken', token)
    }

    case actionTypes.PAYMENT_SET_CARD_PAYMENT_TOKEN: {
      const { token } = action

      return state.set('cardPaymentToken', token)
    }

    case actionTypes.PAYMENT_SET_PAYPAL_NONCE: {
      const { nonce } = action

      return state.set('paypalNonce', nonce)
    }

    case actionTypes.PAYMENT_SET_PAYPAL_DEVICE_DATA: {
      const { deviceData } = action

      return state.set('paypalDeviceData', deviceData)
    }

    case actionTypes.SAVE_APPLE_PAY_CHECKOUT_TOKEN: {
      const { token } = action

      return state.set('applePayToken', token)
    }
    case actionTypes.SAVE_GOOGLE_PAY_TOKEN: {
      const { token } = action

      return state.set('googlePayToken', token)
    }

    case actionTypes.PAYMENT_BILLING_ADDRESS_DIFFERENT: {
      const { isBillingAddressDifferent } = action

      return state.set('isBillingAddressDifferent', isBillingAddressDifferent)
    }

    case actionTypes.PAYMENT_SET_BILLING_ADDRESS: {
      const { postcode, houseNo, street, town, county, udprn } = action

      const billingAddress = Immutable.Map({
        postcode,
        houseNo,
        street,
        town,
        county,
        udprn,
      })

      return state.set('billingAddress', billingAddress)
    }

    default:
      return state
  }
}

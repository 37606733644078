export const SHIPPING_ADDRESS_UNSERVICEABLE = 'SHIPPING_ADDRESS_UNSERVICEABLE'
export const GB_COUNTRY_CODE = 'GB'
export const ERROR_MESSAGES = {
  nonUkAddress: 'We only ship to UK postcodes right now',
  postcodeMismatch:
    "A postcode in this delivery address doesn't match the postcode entered in a previous step",
}

export const PRODUCTION = 'PRODUCTION'
export const TEST = 'TEST'

import Immutable from 'immutable'
import { Action } from 'redux'

import { actionTypes } from 'actions/actionTypes'
import { checkoutConstants } from 'routes/Checkout/checkoutConstants'

const applePayErrors = [
  actionTypes.APPLE_PAY_TOKEN_CONVERSION_ERROR,
  actionTypes.APPLE_PAY_MERCHANT_VALIDATION_ERROR,
]

const googlePayErrors = [actionTypes.GOOGLE_PAY_TOKEN_PARSING_ERROR]

const paypalErrors = [actionTypes.PAYPAL_TOKEN_FETCH_FAILED, actionTypes.PAYPAL_ERROR]

const errorsToCapture = [
  actionTypes.USER_SUBSCRIBE,
  actionTypes.CHECKOUT_SIGNUP,
  actionTypes.CHECKOUT_SIGNUP_LOGIN,
  actionTypes.CHECKOUT_PAYMENT,
  actionTypes.ORDER_SAVE,
  actionTypes.CARD_TOKENIZATION_FAILED,
  actionTypes.NETWORK_FAILURE,
  actionTypes.VALID_CARD_DETAILS_NOT_PROVIDED,
  actionTypes.CUSTOMER_CANCELLED_THREEDSECURE,
  actionTypes.SIGNUP_PAYMENT_FAILED,
  actionTypes.CUSTOMER_NOT_ENROLLED_IN_THREEDSECURE,
  ...applePayErrors,
  ...googlePayErrors,
  ...paypalErrors,
]

const checkoutInitialState = Immutable.fromJS({
  applePay: {
    personalInfo: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
    },
    deliveryAddress: {
      name: '',
      line1: '',
      line2: '',
      line3: '',
      town: '',
      county: '',
      postcode: '',
    },
    deliveryInstruction: checkoutConstants.leaveBoxOptions[0],
    deliveryInstructionCustom: '',
  },
  firstName: '',
  lastName: '',
  deliveryAddress: {
    postcode: '',
    houseNo: '',
    street: '',
    town: '',
    county: '',
    udprn: '',
  },
  phone: '',
  deliveryInstruction: checkoutConstants.leaveBoxOptions[0],
  deliveryInstructionCustom: '',
  deliveryAddresses: [],
  goustoRef: null,
  errors: {},
  applePayErrors: {},
  googlePayErrors: {},
  paypalErrors: {},
  paypalErrorsReported: false,
  lastReachedStepIndex: 0,
  email: '',
  passwordInfo: {
    value: '',
    errorCodes: [],
  },
  isEmailMarketingEnabled: true,
  isCompetitionsAndSurveysEnabled: false,
  recaptchaExecutionStarted: false,
  expressCheckoutRecaptchaExecutionStarted: false,
  isApplePayEnabled: false,
  googlePay: {
    personalInfo: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
    },
    deliveryAddress: {
      name: '',
      line1: '',
      line2: '',
      line3: '',
      town: '',
      county: '',
      postcode: '',
    },
  },
})

type CheckoutStateType = typeof checkoutInitialState
type CheckoutActionType = Action<string> & CheckoutStateType
type AddressList = {
  id: string
  labels: string[]
  count: number
}

export const checkoutReducers = {
  checkout: (state: CheckoutStateType, action: CheckoutActionType) => {
    if (!state) {
      return checkoutInitialState
    }

    switch (action.type) {
      case actionTypes.CHECKOUT_LOAD_APPLE_PAY_ENABLED_STATUS: {
        return state.set('isApplePayEnabled', action.payload)
      }

      case actionTypes.CHECKOUT_ERRORS_CLEAR: {
        return state.set('errors', checkoutInitialState.get('errors'))
      }

      case actionTypes.CHECKOUT_PAYPAL_ERRORS_CLEAR: {
        return state.set('paypalErrors', checkoutInitialState.get('paypalErrors'))
      }

      case actionTypes.APPLE_PAY_ERRORS_CLEAR: {
        return state.set('applePayErrors', checkoutInitialState.get('applePayErrors'))
      }

      case actionTypes.GOOGLE_PAY_ERRORS_CLEAR: {
        return state.set('googlePayErrors', checkoutInitialState.get('googlePayErrors'))
      }

      case actionTypes.CHECKOUT_PAYPAL_ERRORS_REPORTED: {
        return state.set('paypalErrorsReported', true)
      }

      case actionTypes.CHECKOUT_ADDRESSES_RECEIVE: {
        const { addresses } = action
        const addressList: AddressList[] = []
        addresses.forEach((address: AddressList) => {
          addressList.push({
            id: address.id,
            labels: address.labels,
            count: address.count,
          })
        })

        return state.set('deliveryAddresses', Immutable.fromJS(addressList))
      }

      case actionTypes.CHECKOUT_SET_GOUSTO_REF: {
        const { goustoRef } = action

        return state.set('goustoRef', goustoRef)
      }

      case actionTypes.PAYMENT_SET_PAYMENT_METHOD: {
        return state.set('errors', checkoutInitialState.get('errors'))
      }

      case actionTypes.PAYMENT_SET_PAYPAL_CLIENT_TOKEN: {
        return state.deleteIn(['paypalErrors', actionTypes.PAYPAL_TOKEN_FETCH_FAILED])
      }

      case actionTypes.SAVE_APPLE_PAY_CHECKOUT_TOKEN: {
        return state.deleteIn(['applePayErrors', actionTypes.APPLE_PAY_TOKEN_CONVERSION_ERROR])
      }

      case actionTypes.ERROR: {
        if (
          Object.prototype.hasOwnProperty.call(action, 'key') &&
          Object.prototype.hasOwnProperty.call(action, 'value')
        ) {
          if (errorsToCapture.indexOf(action.key) !== -1) {
            let { value } = action

            if (value instanceof Error) {
              value = value.message
            }

            let errors

            if (applePayErrors.indexOf(action.key) !== -1) {
              errors = 'applePayErrors'
            } else if (googlePayErrors.indexOf(action.key) !== -1) {
              errors = 'googlePayErrors'
            } else if (paypalErrors.indexOf(action.key) !== -1) {
              errors = 'paypalErrors'
            } else {
              errors = 'errors'
            }

            return state.setIn([errors, action.key], value)
          }
        }

        return state
      }

      case actionTypes.CHECKOUT_SET_EMAIL: {
        const { email } = action

        return state.set('email', email)
      }

      case actionTypes.CHECKOUT_SET_NAME: {
        const { firstName, lastName } = action

        return state.set('firstName', firstName).set('lastName', lastName)
      }

      case actionTypes.CHECKOUT_SET_DELIVERY_ADDRESS: {
        const { postcode, houseNo, street, town, county, udprn } = action

        const deliveryAddress = new (Immutable as any).Map({
          postcode,
          houseNo,
          street,
          town,
          county,
          udprn,
        })

        return state.set('deliveryAddress', deliveryAddress)
      }

      case actionTypes.CHECKOUT_SET_PHONE: {
        const { phone } = action

        return state.set('phone', phone)
      }

      case actionTypes.CHECKOUT_SET_DELIVERY_INSTRUCTION: {
        const { deliveryInstruction, deliveryInstructionCustom } = action

        return state
          .set('deliveryInstruction', deliveryInstruction)
          .set('deliveryInstructionCustom', deliveryInstructionCustom)
      }

      case actionTypes.CHECKOUT_SET_IS_EMAIL_MARKETING_ENABLED: {
        const { isEmailMarketingEnabled } = action

        return state.set('isEmailMarketingEnabled', isEmailMarketingEnabled)
      }

      case actionTypes.CHECKOUT_SET_IS_COMPETITIONS_AND_SURVEYS_ENABLED: {
        const { isCompetitionsAndSurveysEnabled } = action

        return state.set('isCompetitionsAndSurveysEnabled', isCompetitionsAndSurveysEnabled)
      }

      case actionTypes.CHECKOUT_PASSWORD_VALIDATION_RULES_SET: {
        const { errors, password } = action

        return state
          .setIn(
            ['passwordInfo', 'errorCodes'],
            errors.map((item: Record<string, string>) => item.error),
          )
          .setIn(['passwordInfo', 'value'], password)
      }

      case actionTypes.CHECKOUT_SIGNUP_START_RECAPTCHA_EXECUTION: {
        const { executionStarted } = action

        return state.set('recaptchaExecutionStarted', executionStarted)
      }

      case actionTypes.EXPRESS_CHECKOUT_START_RECAPTCHA_EXECUTION: {
        const { executionStarted } = action

        return state.set('expressCheckoutRecaptchaExecutionStarted', executionStarted)
      }

      case actionTypes.SAVE_APPLE_PAY_DELIVERY_INSTRUCTION: {
        const { deliveryInstruction } = action

        return state.setIn(['applePay', 'deliveryInstruction'], deliveryInstruction)
      }

      case actionTypes.SAVE_APPLE_PAY_DELIVERY_INSTRUCTION_CUSTOM: {
        const { deliveryInstructionCustom } = action

        return state.setIn(['applePay', 'deliveryInstructionCustom'], deliveryInstructionCustom)
      }

      case actionTypes.SAVE_APPLE_PAY_DELIVERY_ADDRESS: {
        const { deliveryAddress } = action

        return state.setIn(['applePay', 'deliveryAddress'], Immutable.Map(deliveryAddress))
      }

      case actionTypes.SAVE_APPLE_PAY_PERSONAL_INFO: {
        const { personalInfo } = action

        return state.setIn(['applePay', 'personalInfo'], Immutable.Map(personalInfo))
      }

      case actionTypes.SAVE_GOOGLE_PAY_DELIVERY_ADDRESS: {
        const { deliveryAddress } = action

        return state.setIn(['googlePay', 'deliveryAddress'], Immutable.Map(deliveryAddress))
      }

      case actionTypes.SAVE_GOOGLE_PAY_PERSONAL_INFO: {
        const { personalInfo } = action

        return state.setIn(['googlePay', 'personalInfo'], Immutable.Map(personalInfo))
      }

      default: {
        return state
      }
    }
  },
}

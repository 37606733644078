import React from 'react'
import { useOrder } from './hooks/useOrder'
import { OrderTile } from './components/OrderTile'
import {
  Box,
  Col,
  Color,
  Display,
  FlexDirection,
  Grid,
  Heading1,
  Heading4,
  Image,
  Join,
  JustifyContent,
  Link,
  Space,
} from '@gousto-internal/citrus-react'
import { useTracking } from './hooks/useTracking'

const getLoadingGif = () => require(`./assets/Loading-Icon.gif`)

export function MyGoustoPage() {
  const { dispatchedOrder, pendingOrders, lastDeliveredOrder, isLoading } = useOrder()
  const { trackViewAllUpcomingDeliveries } = useTracking()
  const upcomingDeliveries = [...pendingOrders]
  if (dispatchedOrder) {
    upcomingDeliveries.unshift(dispatchedOrder)
  }
  const hasUpcomingDeliveries = upcomingDeliveries.length > 0

  if (isLoading) {
    return (
      <Grid gap={[6, 8]} paddingV={[4, 8]} paddingH={[0, 3]} bg={Color.NeutralGrey_50}>
        <Col size={12}>
          <Box
            display={Display.Flex}
            justifyContent={JustifyContent.Center}
            data-testid="orders-loader"
          >
            <Image src={getLoadingGif()} width="100" alt="loader" />
          </Box>
        </Col>
      </Grid>
    )
  }

  if (!hasUpcomingDeliveries && !lastDeliveredOrder) {
    return (
      <Grid gap={[6, 8]} paddingV={[4, 8]} paddingH={[0, 3]} bg={Color.NeutralGrey_50}>
        <Col size={12}>
          <Box>
            <Heading1>Welcome back</Heading1>
            <Space size={5} />
            <Box
              display={Display.Flex}
              justifyContent={JustifyContent.Center}
              data-testid="orders-loader"
            >
              <Heading4>No orders found</Heading4>
            </Box>
          </Box>
        </Col>
      </Grid>
    )
  }

  return (
    <Grid gap={[6, 8]} paddingV={[4, 8]} paddingH={[0, 3]} bg={Color.NeutralGrey_50}>
      <Col size={12}>
        <Box>
          <Heading1>Welcome back</Heading1>
          <Space size={5} />
          <Join with={<Space size={4} direction="vertical" />}>
            <Box
              display={Display.Flex}
              flexDirection={FlexDirection.Row}
              justifyContent={JustifyContent.SpaceBetween}
            >
              {hasUpcomingDeliveries && <Heading4>Upcoming deliveries</Heading4>}
              <Link href="/my-deliveries" onClick={trackViewAllUpcomingDeliveries}>
                View all
              </Link>
            </Box>
            {upcomingDeliveries.map((order) => (
              <OrderTile key={order.id} order={order} />
            ))}
            {lastDeliveredOrder && (
              <>
                <Heading4>Last delivery</Heading4>
                <Space size={4} />
                <OrderTile order={lastDeliveredOrder} />
              </>
            )}
          </Join>
        </Box>
      </Col>
    </Grid>
  )
}

import {
  Box,
  Icon,
  IconVariant,
  JustifyContent,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'
import React from 'react'

const CenteredAndClickableBox = withCitrusTheme(Box, () => ({ margin: 'auto', cursor: 'pointer' }))
const ClickableBox = withCitrusTheme(Box, () => ({ cursor: 'pointer' }))
type Prop = {
  setIsOrderExpanded: (isExpanded: boolean) => void
  isOrderExpanded: boolean
  trackExpandOrder: () => void
}

export const OrderDetailExpandButton = ({
  isOrderExpanded,
  setIsOrderExpanded,
  trackExpandOrder,
}: Prop) => {
  const handleExpandClick = () => {
    setIsOrderExpanded(!isOrderExpanded)
    trackExpandOrder()
  }

  return (
    <>
      {/* Mobile */}
      <ClickableBox
        display={['flex', 'none']}
        justifyContent={JustifyContent.FlexEnd}
        data-testid="order-detail-expand-button-mobile"
        onClick={handleExpandClick}
      >
        <Icon
          name={!isOrderExpanded ? 'chevron_down' : 'chevron_up'}
          variant={IconVariant.Actionable}
          size={6}
        />
      </ClickableBox>
      {/* Desktop */}
      <CenteredAndClickableBox
        display={['none', 'block']}
        data-testid="order-detail-expand-button-desktop"
        onClick={handleExpandClick}
      >
        <Icon
          name={!isOrderExpanded ? 'chevron_down' : 'chevron_up'}
          variant={IconVariant.Actionable}
          size={10}
        />
      </CenteredAndClickableBox>
    </>
  )
}

import {
  Body1,
  Body2,
  Box,
  Button,
  ButtonColorVariant,
  Col,
  Color,
  Display,
  FlexDirection,
  FontWeight,
  Grid,
  Image,
  Join,
  JustifyContent,
  Link,
  Select,
  Space,
} from '@gousto-internal/citrus-react'
import React from 'react'
import { useOrderExpand } from '../hooks/useOrderExpand'

const getLoadingGif = () => require(`../assets/Loading-Icon.gif`)

export const OrderExpanded = () => {
  const {
    availableSlots,
    context,
    humanDeliveryTime,
    handleSelectDate,
    handleSelectTime,
    handleEditClick,
    handleCancelDelivery,
    handleUpdateSlotClick,
    isLoading,
    isUpdatingDeliveryDate,
    showEditDelivery,
    selectedSlot,
    shouldDeleteOrder,
  } = useOrderExpand()

  return (
    <Grid width="100%">
      <Col size={[12, 6]}>
        <Join with={<Space size={3} direction="vertical" />}>
          <Body1 fontWeight={FontWeight.Bold} color={Color.ColdGrey_900}>
            Delivery Details
          </Body1>
          <Box>
            <Box
              display={Display.Flex}
              flexDirection={FlexDirection.Row}
              justifyContent={JustifyContent.SpaceBetween}
            >
              <Box>
                <Body2 fontWeight={FontWeight.Bold} color={Color.ColdGrey_900}>
                  {context.humanDeliveryDate}
                </Body2>
              </Box>
              {context.isOrderEditable && (
                <Box>
                  <Link onClick={handleEditClick}>{!showEditDelivery ? 'Edit' : 'Close'}</Link>
                </Box>
              )}
            </Box>
            <Body2 color={Color.ColdGrey_900}>{humanDeliveryTime}</Body2>
          </Box>
          {isLoading && showEditDelivery && (
            <Box
              display={Display.Flex}
              justifyContent={JustifyContent.Center}
              data-testid="days-loader"
            >
              <Image src={getLoadingGif()} width="50px" alt="loader" />
            </Box>
          )}
          {!isLoading && showEditDelivery && (
            <Box>
              <Join with={<Space size={3} direction="vertical" />}>
                <Box
                  display={Display.Flex}
                  flexDirection={[FlexDirection.Column, FlexDirection.Row]}
                >
                  <Join with={<Space size={3} direction="horizontal" />}>
                    <Box width={['100%', '50%']}>
                      <Select
                        id="delivery-date-select"
                        data-testid="delivery-date-select"
                        onChange={handleSelectDate}
                        fullWidth
                      >
                        {availableSlots?.map((slot, iterator) => (
                          <option
                            key={`date-${iterator}-${slot.id}`}
                            value={slot.date}
                            selected={slot.date === selectedSlot?.date}
                            disabled={slot.isRecipeUnavailable}
                          >
                            {slot.humanizedDate}
                          </option>
                        ))}
                      </Select>
                      <Space size={[3, 0]} direction="vertical" />
                    </Box>
                    <Box width={['100%', '50%']}>
                      <Select
                        id="delivery-time-price-select"
                        status={undefined}
                        defaultValue={selectedSlot?.id}
                        onChange={handleSelectTime}
                        fullWidth
                      >
                        {selectedSlot?.daySlotLeadTimes?.map((dslt) => (
                          <option
                            key={dslt.coreSlotId}
                            value={dslt.id}
                            disabled={selectedSlot.isRecipeUnavailable}
                          >
                            {dslt.startTime} - {dslt.endTime}
                          </option>
                        ))}
                      </Select>
                    </Box>
                  </Join>
                </Box>
                <Box>
                  <Button
                    colorVariant={ButtonColorVariant.Secondary}
                    width="100%"
                    disabled={
                      context.deliveryDate.includes(selectedSlot?.date || '') ||
                      isUpdatingDeliveryDate
                    }
                    onClick={handleUpdateSlotClick}
                  >
                    Update Delivery Slot
                  </Button>
                </Box>
              </Join>
            </Box>
          )}
        </Join>
        <Space size={3} direction="vertical" />
        <Body2 fontWeight={FontWeight.Bold} color={Color.ColdGrey_900}>
          Delivery Address
        </Body2>
        <Body2 color={Color.ColdGrey_900}>
          {context.shippingAddress.line1}, {context.shippingAddress.town},{' '}
          {context.shippingAddress.postcode}
        </Body2>
        <Space size={3} direction="vertical" />
        {context.isOrderEditable && (
          <Button
            disabled={shouldDeleteOrder}
            colorVariant={ButtonColorVariant.Warning}
            width="100%"
            onClick={handleCancelDelivery}
          >
            Cancel delivery
          </Button>
        )}
      </Col>
      <Col size={[12, 6]} display={['none', 'flex']} />
    </Grid>
  )
}

import React from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { Box } from '@gousto-internal/citrus-react'
import { useDispatch } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import statusActions from 'actions/status'
import { setCurrentPaymentMethod, trackClickPayWithApplePay } from 'routes/Checkout/checkoutActions'
import { PaymentMethod } from 'routes/Signup/signupConfig'

import css from './ApplePayButton.css'

type ExpressCheckoutPayWithApplePayProps = {
  initializeApplePay: () => void
}

export const ExpressCheckoutPayWithApplePay = ({
  initializeApplePay,
}: ExpressCheckoutPayWithApplePayProps) => {
  const dispatch = useDispatch()

  // handler for Pay button
  const onPayClick = () => {
    dispatch(setCurrentPaymentMethod(PaymentMethod.ApplePay))
    dispatch({ type: actionTypes.APPLE_PAY_ERRORS_CLEAR })
    dispatch(statusActions.error(actionTypes.CHECKOUT_SIGNUP, null))
    dispatch(statusActions.error(actionTypes.USER_SUBSCRIBE, null))
    dispatch(trackClickPayWithApplePay())
    initializeApplePay()
    datadogLogs.logger.info('ApplePay Session Initiated')
    datadogRum.addAction('ApplePay Session Initiated')
  }

  return (
    <>
      <Box
        paddingBottom={4}
        data-testing="expressCheckoutPayWithApplePay"
        data-testid="expressCheckoutPayWithApplePay"
      >
        <button className={css.applePayButton} type="submit" onClick={onPayClick}>
          <span className={css.applePayButtonText}>Pay with</span>
          <span className={css.applePayButtonLogo} />
        </button>
      </Box>
    </>
  )
}

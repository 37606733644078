import React from 'react'
import { CSSObject } from '@emotion/react'

import {
  AlignItems,
  Body1,
  Box,
  Button,
  Color,
  CSSKeywords,
  Display,
  Display3,
  FlexDirection,
  FontFamily,
  Icon,
  IconVariant,
  Join,
  ResponsiveValue,
  Space,
  Text,
  Visible,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'

import { VALUE_YOU_CAN_TASTE } from '../../../config'

import chickPeasSmall from '../../../assets/ValueYouCanTasteSectionAssets/chickpeas-small.svg'
import chickPeasLarge from '../../../assets/ValueYouCanTasteSectionAssets/chickpeas-large.svg'
import { LandingPageContentSections, trackCTAClick, TrackingActions } from '../../../tracking'

type BenefitsProps = {
  ctaText: string
  onCTAClick: () => void
}

const responsiveBackground: ResponsiveValue<string> = [
  `85% -12px / 639px no-repeat url(${chickPeasSmall})`,
  `-200px / auto 480px no-repeat url(${chickPeasLarge})`,
  null,
  `-150px / auto 480px no-repeat url(${chickPeasLarge})`,
  `-80px / auto 480px no-repeat url(${chickPeasLarge})`,
]

const Container = withCitrusTheme(Box, (theme) => ({
  ...(theme.responsiveStyle(responsiveBackground, ['background']) as CSSObject),
}))

export function Benefits({ ctaText, onCTAClick }: BenefitsProps) {
  const clickCTA = () => {
    onCTAClick()
    trackCTAClick(TrackingActions.CLICK_GET_STARTED, LandingPageContentSections.VALUE_YOU_CAN_TASTE)
  }

  const benefitList = VALUE_YOU_CAN_TASTE.benefits.map(({ regularText, boldText }) => (
    <Box display={Display.Flex} flexDirection={FlexDirection.Row} key={regularText}>
      <Box color={Color.Success_400}>
        <Icon name="tick" variant={IconVariant.Inherit} />
      </Box>
      <Space direction="horizontal" size={1} />
      <Body1 color={Color.White}>
        {regularText}{' '}
        {boldText && (
          <Text display={Display.InlineBlock} fontFamily={FontFamily.Bold}>
            {boldText}
            {<sup>†</sup>}
          </Text>
        )}
      </Body1>
    </Box>
  ))

  return (
    <Container
      alignItems={[AlignItems.FlexStart, null, null, AlignItems.Center]}
      display={Display.Flex}
      height={[null, '100%']}
      paddingH={[6, 4, 6, 20]}
      paddingV={[14, 16]}
      paddingBottom={[0]}
    >
      <Box width={[null, null, null, '430px']}>
        <Box width={['75%', CSSKeywords.Unset]}>
          <Display3 color={Color.White}>Value you can taste</Display3>
        </Box>
        <Space size={[4, 5]} />
        <Join with={<Space size={2} />}>{benefitList}</Join>
        <Space size={[0, 5]} />
        <Visible display={[Display.None, null, Display.Block]}>
          <Box width="100%">
            <Button width="320px" onClick={clickCTA} height="48px">
              {ctaText}
            </Button>
          </Box>
        </Visible>
      </Box>
    </Container>
  )
}

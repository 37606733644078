import BritishBroadcastingCorporationPNG from './assets/BrandEndorsementsSectionAssets/bbc.png'
import BritishBroadcastingCorporationWEBP from '../src/assets/BrandEndorsementsSectionAssets/bbc.webp'
import EveningStandardPNG from '../src/assets/BrandEndorsementsSectionAssets/eveningstandard.png'
import EveningStandardWEBP from '../src/assets/BrandEndorsementsSectionAssets/eveningstandard.webp'
import IndependentPNG from '../src/assets/BrandEndorsementsSectionAssets/independent.png'
import IndependentWEBP from '../src/assets/BrandEndorsementsSectionAssets/independent.webp'
import MarieClairePNG from '../src/assets/BrandEndorsementsSectionAssets/marieclaire.png'
import MarieClaireWEBP from '../src/assets/BrandEndorsementsSectionAssets/marieclaire.webp'
import TheTelegraphPNG from '../src/assets/BrandEndorsementsSectionAssets/thetelegraph.png'
import TheTelegraphWEBP from '../src/assets/BrandEndorsementsSectionAssets/thetelegraph.webp'

export const BRAND_ENDORSEMENTS = {
  header: 'Tested and loved by',
  images: {
    britishBroadcastingCorporation: {
      png: BritishBroadcastingCorporationPNG,
      webp: BritishBroadcastingCorporationWEBP,
    },
    eveningStandard: {
      png: EveningStandardPNG,
      webp: EveningStandardWEBP,
    },
    independent: {
      png: IndependentPNG,
      webp: IndependentWEBP,
    },
    marieClaire: {
      png: MarieClairePNG,
      webp: MarieClaireWEBP,
    },
    theTelegraph: {
      png: TheTelegraphPNG,
      webp: TheTelegraphWEBP,
    },
  },
}

export const ENDLESS_VARIETY = {
  heading: 'Endless Variety',
  firstParagraph: 'Discover over 250 recipes a month with new releases every Tuesday',
  collections: [
    'Vegetarian',
    'Vegan',
    'Gluten-free',
    'Dairy-free',
    'Healthy choices',
    'Family classics',
    'Quick and easy',
    'Global cuisines',
  ],
}

export const EFFORTLESS_HOME_COOKING = {
  header: 'Effortless Home Cooking',
  benefits: [
    'Plan your weekly meals in a few clicks',
    'Follow easy-to-use instructions on printed recipe cards',
    'Dish up delicious dinners after a busy day in as little as 10 mins',
  ],
}

export const TRUSTPILOT_SECTION = {
  headingFirst: 'Over',
  headingSecond: '143 million meals',
  headingThird: 'cooked by Gousto lovers since 2013',
}

export const ENVIRONMENTAL_BENEFITS_HEADING = {
  eyebrowHeading: 'Meals that',
  headingFirst: "Don't cost the earth",
}

export const ENVIRONMENTAL_BENEFITS_BULLET_ONE = {
  firstLine: 'By the end of 2025, your Gousto box will be full of',
  secondLine: '100% recyclable, reusable or compostable own-brand packaging',
  thirdLine: 'as part of the UK Plastics Pacts',
}

export const ENVIRONMENTAL_BENEFITS_BULLET_TWO = {
  firstLine: 'Each Gousto box ',
  secondLine: 'cuts your carbon footprint by 23%',
  thirdLine: 'compared to the same shop at a supermarket',
  sup: '‡',
}

export const ENVIRONMENTAL_BENEFITS_DONATION_COPY = {
  firstLine: 'In 2022, we donated',
  secondLine: '970,000+ meals',
  thirdLine: 'worth of food to those in need',
}

export const VALUE_YOU_CAN_TASTE = {
  header: 'Value you can taste',
  startingPrice: '£2.99',
  benefits: [
    { regularText: 'Hack your grocery bills with meals starting at just', boldText: '£2.99' },
    { regularText: 'Unpack fresh, quality ingredients for maximum flavour', boldText: '' },
    {
      regularText: 'Eliminate food waste with exact quantities - no more money in the bin',
      boldText: '',
    },
  ],
  videoURL: ' https://production-media.gousto.co.uk/videos/hp-video-550px.mp4',
}

import { Order } from '@library/api-user'
import React, { useCallback } from 'react'
import { useOrderTile } from '../hooks/useOrderTile'
import { useOrderTracking } from '../hooks/useOrderTracking'
import { OrderTileContext } from '../hooks/useOrderTileContext'
import { OrderTileWithRecipes } from './OrderTileWithRecipes'
import { OrderTileWithoutRecipes } from './OrderTileWithoutRecipe'
import { TrackingContext } from '../hooks/useTrackingContext'

type Props = {
  order: Order
}

export const OrderTile = ({ order }: Props) => {
  const { data, isOrderExpanded, setIsOrderExpanded, setShouldRestoreOrder, shouldRestoreOrder } =
    useOrderTile(order)
  const {
    trackChooseRecipes,
    trackPlates,
    trackGetHelp,
    trackRestoreOrder,
    trackCancelOrder,
    trackRecommendedRecipe,
    trackEditRecipes,
    trackExpandOrder,
  } = useOrderTracking(order)

  const handleRestoreRecipes = useCallback(() => {
    setShouldRestoreOrder(true)
  }, [setShouldRestoreOrder])

  return (
    <OrderTileContext.Provider value={data}>
      <TrackingContext.Provider
        value={{
          trackChooseRecipes,
          trackPlates,
          trackGetHelp,
          trackRestoreOrder,
          trackCancelOrder,
          trackRecommendedRecipe,
          trackEditRecipes,
          trackExpandOrder,
        }}
      >
        {(data.areRecipesChosenForPendingOrder ||
          data.isOrderCommitted ||
          data.isOrderOnPreMenu ||
          data.isTransactionalOrderCancelled) && (
          <OrderTileWithRecipes
            isOrderExpanded={isOrderExpanded}
            setIsOrderExpanded={setIsOrderExpanded}
          />
        )}
        {(data.isAwaitingChoices || data.isOrderRestorable) && (
          <OrderTileWithoutRecipes
            handleRestoreRecipes={handleRestoreRecipes}
            isOrderExpanded={isOrderExpanded}
            setIsOrderExpanded={setIsOrderExpanded}
            shouldRestoreOrder={shouldRestoreOrder}
          />
        )}
      </TrackingContext.Provider>
    </OrderTileContext.Provider>
  )
}

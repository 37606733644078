import React, { Fragment } from 'react'

import {
  AlignItems,
  Box,
  Color,
  Display,
  FlexDirection,
  FontFamily,
  Heading2,
  JustifyContent,
  Space,
  Text,
  TextAlign,
} from '@gousto-internal/citrus-react'

import { contentBlocks, quotes } from 'routes/BoxPrices/boxPricesConfig'

import { BoxPricesDiscountInfographic } from '../BoxPricesDiscountInformation/BoxPricesDiscountInfographic'
import { BoxPricesDiscountInformation } from '../BoxPricesDiscountInformation/BoxPricesDiscountInformation'
import { BoxPricesQuotationBlock } from './BoxPricesQuotationBlock'

type ContentBlockProps = {
  block: {
    titles: string[]
    texts: string[]
  }
}

type BoxPricesContentProps = {
  activeNumPortion: number
}

export const ContentBlock = ({ block }: ContentBlockProps) => (
  <Box
    height="100%"
    width="100%"
    maxWidth={['343px', '552px']}
    justifyContent={JustifyContent.Center}
    data-testid="boxPricesContentItem"
  >
    {block.titles.map((title) => (
      <>
        <Heading2
          size={[9, 10]}
          data-testid="boxPricesContentTitle"
          key={`box-prices-content-${block.titles[0]}`}
        >
          {title}
        </Heading2>
        <Space size={2} />
        <Text fontFamily={FontFamily.Book} size={[4, 6]} textAlign={TextAlign.Left}>
          {block.texts[0]}
        </Text>
        <Space size={3} />
        <Text fontFamily={FontFamily.Book} size={[4, 6]} textAlign={TextAlign.Left}>
          {block.texts[1]}
        </Text>
        <Space size={2} direction="horizontal" />
      </>
    ))}
  </Box>
)

export const BoxPricesContent = ({ activeNumPortion }: BoxPricesContentProps) => {
  const blocks = contentBlocks

  return (
    <Fragment>
      <BoxPricesDiscountInformation>
        <BoxPricesDiscountInfographic activeNumPortion={activeNumPortion} />
      </BoxPricesDiscountInformation>

      <Box width="100%" bg={Color.White} data-testid="boxPricesContent">
        <Space size={8} />
        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          justifyContent={JustifyContent.SpaceEvenly}
          alignItems={AlignItems.Center}
          paddingBottom={2}
        >
          {blocks.map((contentBlock) => (
            <>
              <ContentBlock
                key={`box-prices-content-${contentBlock.titles[0]}`}
                block={contentBlock}
              />
            </>
          ))}
        </Box>
      </Box>

      <Box
        maxWidth="100%"
        display={Display.Flex}
        alignItems={AlignItems.Center}
        flexDirection={FlexDirection.Column}
        justifyContent={JustifyContent.Center}
        data-testing="boxPricesQuotationBlockContainer"
      >
        <Space size={6} />
        {quotes.map((quote, index) => (
          <>
            <BoxPricesQuotationBlock key={`box-prices-quote-${quote.source}`} quote={quote} />
            {index === 0 && <Space size={[6, 6, 8]} />}
          </>
        ))}
      </Box>
    </Fragment>
  )
}

import React from 'react'

import { Box, Space } from '@gousto-internal/citrus-react'
import { RibbonTriggerContainer } from 'RibbonTrigger'
import * as braintree from 'braintree-web'

import { useIsCheckoutFlexibilityAwarenessEnabled } from 'hooks/useIsCheckoutFlexibilityAwarenessEnabled'
import { BoxFlexibilityAwareness } from 'routes/Checkout/Components/BoxFlexibilityAwareness/BoxFlexibilityAwareness'
import { DeliveryDetailsForm } from 'routes/Checkout/Components/DeliveryDetailsForm'
import { CheckoutStepIds } from 'routes/Checkout/checkoutConfig'

type DeliveryProps = {
  onStepChange: (stepId: CheckoutStepIds) => void
  braintreeClientInstance: braintree.Client | null
  googlePayInstance: braintree.GooglePayment | null
}

export const Delivery = ({
  onStepChange,
  braintreeClientInstance,
  googlePayInstance,
}: DeliveryProps) => {
  const isCheckoutFlexibilityAwarenessEnabled = useIsCheckoutFlexibilityAwarenessEnabled()

  return (
    <>
      <DeliveryDetailsForm
        onStepChange={onStepChange}
        braintreeClientInstance={braintreeClientInstance}
        googlePayInstance={googlePayInstance}
      />

      {isCheckoutFlexibilityAwarenessEnabled && (
        <Box paddingH={[3, 0]}>
          <Space size={4} />
          <BoxFlexibilityAwareness />
        </Box>
      )}

      <RibbonTriggerContainer name="checkout-delivery" />
    </>
  )
}

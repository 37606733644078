export class browserHelperUtils {
  static isChrome() {
    return navigator && navigator.userAgent && navigator.userAgent.indexOf('Chrome/') !== -1
  }
}

export const canUseDom = () =>
  typeof window !== 'undefined' && window.document && window.document.createElement

export const checkCookiesEnabled = (): boolean => {
  try {
    if (!canUseDom()) {
      return false
    }

    // Create a test cookie
    document.cookie = 'cookietest=1'
    const cookieExists = document.cookie.indexOf('cookietest=') !== -1
    // Delete the test cookie
    document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT'

    return cookieExists
  } catch (e) {
    return false
  }
}

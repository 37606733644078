import { composeFetch, composeParser } from '@library/http'
import {
  addPath,
  auth,
  composeRequest,
  setMethod,
  setQueryParam,
  setServiceUrl,
} from '@library/http/requests'
import { parseGoustoBasicResponse } from '@library/http/src/parsers'
import { DaysResponse } from './interfaces'

type QueryParam = {
  filters: { cutoff_datetime_from: string; cutoff_datetime_until: string }
  ndd: string
  delivery_tariff_id: string
  sort: string
  direction: string
  postcode: string
}

const fetchDaysForUser = composeFetch(
  composeRequest<QueryParam>(
    setServiceUrl('deliveries', 1),
    setQueryParam(
      'filters[cutoff_datetime_from]',
      (params: QueryParam) => params.filters.cutoff_datetime_from,
    ),
    setQueryParam(
      'filters[cutoff_datetime_until]',
      (params: QueryParam) => params.filters.cutoff_datetime_until,
    ),
    setQueryParam('ndd', (params: QueryParam) => params.ndd),
    setQueryParam('delivery_tariff_id', (params: QueryParam) => params.delivery_tariff_id),
    setQueryParam('sort', (params: QueryParam) => params.sort),
    setQueryParam('direction', (params: QueryParam) => params.direction),
    setQueryParam('postcode', (params: QueryParam) => params.postcode),
    addPath('days'),
    setMethod('GET'),
    auth,
  ),
  composeParser(
    parseGoustoBasicResponse,
    (basicResponse: unknown) => basicResponse as DaysResponse,
    (response: DaysResponse) => response.data,
  ),
)

export { fetchDaysForUser }

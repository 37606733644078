import { useCallback, useEffect, useState } from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import * as braintree from 'braintree-web'
import { useDispatch, useSelector } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import {
  applePayPaymentWithBraintree,
  fireCheckoutError,
  validateApplePaySessionForBraintree,
  validateShippingContact,
} from 'routes/Checkout/checkoutActions'
import { getDeliveryTimeAndDate } from 'routes/Checkout/checkoutSelectors'
import { useIsApplePayEnabled } from 'routes/Checkout/hooks'
import { createApplePaySession } from 'routes/Checkout/utils/applePaySessionUtils'
import { Pricing, usePricing } from 'routes/Menu/domains/pricing'
import {
  getDeliveryDetailsInstructions,
  getDeliveryDetailsInstructionsCustom,
} from 'selectors/deliveryDetails'

type useApplePayProps = {
  braintreeClientInstance: braintree.Client | null
}

export const useBraintreeApplePayExpressCheckout = ({
  braintreeClientInstance,
}: useApplePayProps) => {
  const dispatch = useDispatch()
  const [applePayInstance, setApplePayInstance] = useState<braintree.ApplePay | null>(null)
  const [canMakePaymentsWithActiveCard, setCanMakePaymentsWithActiveCard] = useState<
    null | boolean
  >(null)
  const { pricing } = usePricing()
  const deliveryInstruction = useSelector(getDeliveryDetailsInstructions)
  const deliveryInstructionCustom = useSelector(getDeliveryDetailsInstructionsCustom)
  const deliveryInstructionLabel =
    deliveryInstruction + (deliveryInstructionCustom ? `: ${deliveryInstructionCustom}` : '')
  const { deliveryTime, deliveryDate } = useSelector(getDeliveryTimeAndDate)
  const isApplePayEnabled = useIsApplePayEnabled()

  const initApplePay = useCallback(async () => {
    const createApplePayInstance = async () => {
      if (!braintreeClientInstance || !isApplePayEnabled) return
      const instance = await braintree.applePay.create({
        client: braintreeClientInstance,
      })
      setApplePayInstance(instance)
    }
    try {
      await createApplePayInstance()
    } catch (error) {
      dispatch(fireCheckoutError(actionTypes.APPLE_PAY_SESSION_FAILED))
      datadogLogs.logger.error(`init apple pay instance failed ${JSON.stringify(error)}`)
    }
  }, [braintreeClientInstance, dispatch, isApplePayEnabled])

  // create apple pay payload
  const createPaymentRequest = () => {
    if (!applePayInstance || !pricing) return null

    return applePayInstance.createPaymentRequest({
      total: {
        label: 'Total',
        amount: pricing.total as string,
      },
      requiredShippingContactFields: ['name', 'phone', 'postalAddress'],
      shippingType: 'delivery',
      shippingMethods: [
        {
          label: `${deliveryDate} ${deliveryTime}`,
          detail: deliveryInstructionLabel,
          amount: pricing.deliveryTotal ?? '0.00',
          identifier: `${deliveryDate} ${deliveryTime}`,
        },
      ],
    }) as braintree.ApplePayPaymentRequest
  }

  // initialise apple pay session
  const initApplePaySession = (
    paymentRequest: braintree.ApplePayPaymentRequest,
    amount: Pricing,
  ) => {
    const session = createApplePaySession(paymentRequest, {
      onvalidatemerchant: (event) =>
        dispatch(validateApplePaySessionForBraintree(event, session, applePayInstance)),
      onpaymentauthorized: (event) =>
        dispatch(applePayPaymentWithBraintree(event, session, pricing, applePayInstance)),
      onshippingcontactselected: (event) => {
        const errors = dispatch(validateShippingContact(event.shippingContact))
        session.completeShippingContactSelection({
          newTotal: {
            type: 'final',
            label: 'Total',
            amount: amount.total as string,
          },
          errors: errors as any,
        })
      },
    })
  }

  const handleSubmit = () => {
    try {
      const paymentRequest = createPaymentRequest()
      if (!applePayInstance || !pricing || !paymentRequest) return
      initApplePaySession(paymentRequest, pricing)
      datadogRum.addAction('click_pay_with_apple_pay')
    } catch (error) {
      dispatch(fireCheckoutError(actionTypes.APPLE_PAY_SESSION_FAILED))
      datadogLogs.logger.error(`init apple pay session failed with ${JSON.stringify(error)}`)
    }
  }

  useEffect(() => {
    const fetchCanMakePayments = async () => {
      if (!applePayInstance || !applePayInstance.merchantIdentifier) return

      try {
        const isValid = await ApplePaySession.canMakePaymentsWithActiveCard(
          applePayInstance.merchantIdentifier as string,
        )

        setCanMakePaymentsWithActiveCard(isValid)
        datadogRum.addAction(`can_pay_with_apple_pay_${isValid}`)
      } catch (error) {
        datadogLogs.logger.error(`fetchCanMakePayments ${error}`)
      }
    }

    fetchCanMakePayments()
  }, [applePayInstance])

  // to initalise ApplePay
  useEffect(() => {
    if (braintreeClientInstance) {
      initApplePay()
    }
  }, [braintreeClientInstance, initApplePay])

  return { handleSubmit, canMakePaymentsWithActiveCard }
}

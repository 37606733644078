import React from 'react'

import Link from 'Link'
import { Row } from 'Page/Grid'
import Immutable from 'immutable'
import useSWR from 'swr'

import { UserCookBookResponseRecipe, userCookbook } from '@library/api-cookbook'

import { client } from 'config/routes'

import placeholderSrc from 'media/images/product-placeholder.png'

import { Section } from '../Section'
import { RecipeCard } from './RecipeCard'

import css from './Cookbook.css'

const renderRecipes = (recipes: UserCookBookResponseRecipe[]) => {
  let recipeCards: (React.ReactNode | null)[] = []

  recipeCards = (recipes ?? []).map((recipe) => {
    const title = recipe.attributes.name
    const id = recipe.attributes.core_recipe_id
    const images = Immutable.fromJS(
      recipe.attributes.images.find((image) => image.type === 'mood-image')?.crops,
    )
    const url = `${client.cookbookRecipeById}/${id}`

    if (!images) {
      return null
    }

    return <RecipeCard key={title} title={title} link={url} images={images} placeholder={false} />
  })

  if (recipeCards.length < 6) {
    const emptyCards = 6 - recipeCards.length

    for (let i = 0; i < emptyCards; i++) {
      recipeCards.push(<RecipeCard images={placeholderSrc} placeholder />)
    }
  }

  return recipeCards.slice(0, 6)
}

export const Cookbook = ({
  rateRecipeCount,
  trackClickRateRecipes,
}: {
  rateRecipeCount: number
  trackClickRateRecipes: (location: string) => void
}) => {
  const { data: response } = useSWR({ limit: 6, pageAfter: 'START' }, userCookbook, {
    shouldRetryOnError: false,
  })

  const recipes = response?.data

  return recipes ? (
    <Section title="Your recent recipes" alternateColour>
      {rateRecipeCount && rateRecipeCount > 0 ? (
        <div className={css.desktopHide}>
          <Link
            className={css.rateRecipesButton}
            to="/rate-my-recipes"
            clientRouted={false}
            tracking={() => {
              trackClickRateRecipes('mygousto_button')
            }}
          >
            <span data-testid="rate-recipe-button-mobile-view">
              Rate your recipes ({rateRecipeCount})
            </span>
          </Link>
        </div>
      ) : null}
      <div data-testing="myGoustoCookbook">
        <Row data-testing="recentPreviousRecipes">{renderRecipes(recipes)}</Row>
      </div>
      {rateRecipeCount && rateRecipeCount > 0 ? (
        <div className={css.mobileHide} data-testing="recentPreviousRecipe">
          <Link
            className={css.rateRecipesButton}
            to="/rate-my-recipes"
            clientRouted={false}
            tracking={() => {
              trackClickRateRecipes('mygousto_button')
            }}
          >
            <span data-testid="rate-recipe-button-desktop-view">
              Rate your recipes ({rateRecipeCount})
            </span>
          </Link>
        </div>
      ) : null}
    </Section>
  ) : null
}

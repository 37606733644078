import React from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import { Section } from '../Section'
import { ReferAFriend } from './ReferAFriend'

const propTypes = {
  userGetReferralDetails: PropTypes.func.isRequired,
  referralDetails: PropTypes.instanceOf(Immutable.Map),
  redirect: PropTypes.func,
}

const defaultProps = {
  referralDetails: Immutable.Map(),
  redirect: () => {},
}

class ReferAFriendWrapper extends React.PureComponent {
  componentDidMount() {
    const {
      userGetReferralDetails,
    } = this.props

    userGetReferralDetails()
  }

  render() {
    const {
      referralDetails,
      redirect,
    } = this.props

    return (
      <Section title="Your Gousto wins" alternateColour>
        <ReferAFriend referralDetails={referralDetails} redirect={redirect} />
      </Section>
    )
  }
}

ReferAFriendWrapper.propTypes = propTypes
ReferAFriendWrapper.defaultProps = defaultProps

export { ReferAFriendWrapper }

import { RecipeData, RecipeType } from './types/recipeData'

export const CTAs = {
  floatingCTABoldText: 'No lock in: ',
  floatingCTAText: 'pause or cancel for free anytime',
}

export const HERO_CAROUSEL_RECIPE_DATA: RecipeData[] = [
  {
    name: 'Roasted Squash Grain Bowl, Miso-Tahini Dressing',
    imageUrl:
      'https://production-media.gousto.co.uk/cms/mood-image/2228---Roasted-Squash-Grain-Bowl-Miso-Tahini-Dressing-4784-1574555317504-x200.jpg',
    type: RecipeType.Vegan,
  },
  {
    name: 'The Ultimate Vegan Tower Burger',
    imageUrl:
      'https://production-media.gousto.co.uk/cms/mood-image/2536_The-Ultimate-Vegan-Stack-Burger-3705-1607343141509-x200.jpg',
    type: RecipeType.Vegan,
  },
  {
    name: 'Blackened fish tacos',
    imageUrl:
      'https://production-media.gousto.co.uk/cms/mood-image/2554---blackened-fish-tacos-6816-1579537734414-x200.jpg',
    type: null,
  },
  {
    name: 'Roasted squash and jewelled bulgar',
    imageUrl:
      'https://production-media.gousto.co.uk/cms/mood-image/2414---Roasted-squash-and-jewelled-bulgar-6764-1578913075256-x200.jpg',
    type: RecipeType.Vegan,
  },
  {
    name: 'Salmon With Spicy Sesame Noodles',
    imageUrl:
      'https://production-media.gousto.co.uk/cms/mood-image/1905---Salmon-With-Spicy-Sesame-Noodles-1645533447656-x200.jpg',
    type: null,
  },
  {
    name: 'Nasi Goreng With Cucumber And Sriracha',
    imageUrl:
      'https://production-media.gousto.co.uk/cms/mood-image/1654_Nasi-Goreng-With-Sriracha-2282-1614686695846-x200.jpg',
    type: RecipeType.Vegetarian,
  },
  {
    name: 'Crispy Basa With Jersey Royals And Herby Dressing',
    imageUrl:
      'https://production-media.gousto.co.uk/cms/mood-image/2910_Crispy-Fish-Buttery-Potatoes--Sauce-Vierge-2382-1598345049220-x200.jpg',
    type: null,
  },
  {
    name: 'American-Style Corn Salad With Ranch Dressing',
    imageUrl:
      'https://production-media.gousto.co.uk/cms/mood-image/2236_American-Corn-Salad-With-Ranch-Dressing_0561-1588586763860-x200.jpg',
    type: RecipeType.Vegetarian,
  },
  {
    name: 'Glazed Tofu Balls With Vegetable Noodle Stir-Fry',
    imageUrl:
      'https://production-media.gousto.co.uk/cms/mood-image/1712---Glazed-Tofu-Balls-With-Vegetable-Noodle-Stir-Fry-without-Spring-onion0898-1646214132152-x200.jpg',
    type: RecipeType.Vegetarian,
  },
  {
    name: 'Paneer Butter Masala With Rice And Naan',
    imageUrl:
      'https://production-media.gousto.co.uk/cms/mood-image/2337---Paneer-Butter-Masala-With-Coriander-Naan12793-1645608840813-x200.jpg',
    type: RecipeType.Vegetarian,
  },
  {
    name: 'Lemony Green Veg Gnocchi',
    imageUrl:
      'https://production-media.gousto.co.uk/cms/mood-image/2497---Lemon-Butter-Gnocchi-With-Green-Veg-3698-1571737723480-x200.jpg',
    type: RecipeType.Vegetarian,
  },
  {
    name: 'Tantanmen Stir-Fried Tofu Ramen With Chilli Oil',
    imageUrl:
      'https://production-media.gousto.co.uk/cms/mood-image/2150---Miso-Sesame-Noodle-Ramen-with-Tofu-2196-1571155285780-x200.jpg',
    type: RecipeType.Vegan,
  },
  {
    name: "General Tso's Chicken With Rice And Broccoli",
    imageUrl:
      'https://production-media.gousto.co.uk/cms/mood-image/2275---moodshot-General-Tsos-Chicken-With-Rice--Broccoli--0490-x200.jpg',
    type: null,
  },
  {
    name: 'DIY Pizza Margherita With Chilli Oil',
    imageUrl:
      'https://production-media.gousto.co.uk/cms/mood-image/2550_Pizza-Margherita-With-Chilli-Oil-2056-1598284836062-x200.jpg',
    type: RecipeType.Vegetarian,
  },
  {
    name: 'Mediterranean-Style Vegetable Panzanella',
    imageUrl:
      'https://production-media.gousto.co.uk/cms/mood-image/3472---Plant-Based-Roasted-Mediterranean-Veg-Panzanella2707-copy-1622563885493-x200.jpg',
    type: RecipeType.Vegan,
  },
  {
    name: 'Steak Taco Salad With Honey Chipotle Dressing',
    imageUrl:
      'https://production-media.gousto.co.uk/cms/mood-image/2724_Steak-Taco-Salad-With-Honey-Chipotle-Dressing_001-1644575828801-x200.jpg',
    type: null,
  },
  {
    name: 'Crispy Chilli Beef Udon Broth',
    imageUrl:
      'https://production-media.gousto.co.uk/cms/mood-image/2676---Crispy-Chilli-Beef-Udon-Broth12479-1643735729189-x200.jpg',
    type: null,
  },
  {
    name: 'Popcorn Chicken Cobb Salad With Ranch-Style Dressing',
    imageUrl:
      'https://production-media.gousto.co.uk/cms/mood-image/3989_Popcorn-Chicken-Cobb-Salad--Ranch-Style-Dressing-7276-1638790873228-x200.jpg',
    type: null,
  },
  {
    name: "Chicken & 'Nduja Parmigiana Burger With Caprese Salad",
    imageUrl:
      'https://production-media.gousto.co.uk/cms/mood-image/4041_Chicken--Nduja-Parmigiana-Burger-With-Caprese-Salad-8131-1642094227428-x200.jpg',
    type: null,
  },
  {
    name: 'Chicken & King Prawn Thai Green Curry',
    imageUrl:
      'https://production-media.gousto.co.uk/cms/mood-image/2783_Thai-Green-Chicken--Prawn-Curry-0480-1588589871840-x200.jpg',
    type: null,
  },
  {
    name: 'Creamy Carbonara Risotto With Balsamic Tomatoes',
    imageUrl:
      'https://production-media.gousto.co.uk/cms/mood-image/3296---Creamy-Carbonara-Risotto-With-Balsamic-Tomatoes0199-1616494922986-x200.jpg',
    type: null,
  },
  {
    name: "Goats' Cheese, 'Nduja & Spinach Tortiglioni",
    imageUrl:
      'https://production-media.gousto.co.uk/cms/mood-image/2885_Goats-Cheese-Chorizo--Spinach-Tortiglioni-2436-1598952604692-x200.jpg',
    type: null,
  },
  {
    name: 'Peruvian-Style Chicken With Chickpea Salad And Rice',
    imageUrl:
      'https://production-media.gousto.co.uk/cms/mood-image/2680_Peruvian-Charred-Chicken--Aji-Verde-2417-1585312527643-x200.jpg',
    type: null,
  },
]

export const HERO = {
  header: 'Mouthwatering home-cooked meals',
  subheader: 'Over 250 recipes a month from just £2.99 a meal',
  sup: '†',
  benefits: '85+ veggie (incl vegan) recipes a month',
  subscriptionBenefitBold: 'No lock in:',
  subscriptionBenefit: 'pause or cancel for free anytime',
}

import {
  Box,
  Display,
  FlexDirection,
  Color,
  BorderStyle,
  Grid,
  Col,
  Join,
  Space,
  JustifyContent,
  AlignItems,
  Body2,
  Body3,
  FontWeight,
  Heading5,
  Button,
  ButtonColorVariant,
  Text,
  Link,
  Icon,
  IconVariant,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'
import React, { useCallback } from 'react'
import { DiscountPrice } from './Discount'
import { OrderDetailExpandButton } from './OrderDetail'
import { RecipeImageTiles } from './RecipeImageTiles'
import { OrderExpanded } from './OrderExpanded'
import { useOrderTileContext } from '../hooks/useOrderTileContext'
import { useTrackingContext } from '../hooks/useTrackingContext'
import { browserHistory } from 'react-router'

type Props = {
  isOrderExpanded: boolean
  setIsOrderExpanded: (isExpanded: boolean) => void
}

const LinkWithoutUnderline = withCitrusTheme(Link, () => ({
  textDecoration: 'none',
  boxShadow: 'none',
}))

export const OrderTileWithRecipes = ({ isOrderExpanded, setIsOrderExpanded }: Props) => {
  const context = useOrderTileContext()
  const { trackGetHelp, trackEditRecipes, trackExpandOrder } = useTrackingContext()

  const { orderId, recipeItems, productItems } = context
  const recipeCount = recipeItems.length
  const productCount = productItems.length

  const handleGetHelpClick = useCallback(() => {
    trackGetHelp()
    browserHistory.push(`/get-help?orderId=${orderId}`)
  }, [orderId, trackGetHelp])

  const handleSendEditRecipeTracking = useCallback(() => {
    trackEditRecipes()
  }, [trackEditRecipes])

  return (
    <Box
      width={'100%'}
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      borderWidth={0.5}
      borderColor={Color.ColdGrey_100}
      borderStyle={BorderStyle.Solid}
      paddingV={[3, 8]}
      paddingH={[3, 8]}
      bg={Color.White}
    >
      <Grid width="100%" gap={[0, 10]}>
        <Col size={[12, 12, 3]}>
          <Box display={'flex'} flexDirection={FlexDirection.Column} height={'100%'}>
            <Join with={<Space size={[4, 6]} direction="vertical" />}>
              <Box
                display={'flex'}
                flexDirection={FlexDirection.Row}
                justifyContent={JustifyContent.SpaceBetween}
                height={'100%'}
              >
                <Box
                  height={'100%'}
                  display={'flex'}
                  flexDirection={FlexDirection.Column}
                  justifyContent={JustifyContent.SpaceBetween}
                  width={'100%'}
                >
                  <Box
                    height={'100%'}
                    display={Display.Flex}
                    justifyContent={JustifyContent.SpaceBetween}
                  >
                    <Box>
                      <Heading5>{context.humanDeliveryDate}</Heading5>
                      <Body2 color={Color.Success_800} fontWeight={FontWeight.Bold}>
                        {context.orderPhaseContent}
                      </Body2>

                      <Body3 size={[1, 2]}>
                        {recipeCount} {recipeCount <= 0 ? 'recipe' : 'recipes'}, {productCount}{' '}
                        {productCount <= 0 ? 'item' : 'items'}
                      </Body3>
                      <Space size={[3]} />
                    </Box>
                    {context.isOrderOnPreMenu ? null : (
                      <Box display={['flex', 'none']} flexDirection={FlexDirection.Column}>
                        <OrderDetailExpandButton
                          isOrderExpanded={isOrderExpanded}
                          setIsOrderExpanded={setIsOrderExpanded}
                          trackExpandOrder={trackExpandOrder}
                        />
                        <DiscountPrice />
                      </Box>
                    )}
                  </Box>
                  <>
                    {context.isOrderOnPreMenu && (
                      <>
                        <Body3 color={Color.ColdGrey_800} size={2}>
                          The menu will open by midday
                        </Body3>
                        <Space size={[1, 0]} />
                      </>
                    )}
                    {context.areRecipesChosenForPendingOrder && (
                      <>
                        <Body3
                          color={Color.ColdGrey_800}
                          size={2}
                        >{`Edit recipes up until on ${context.shouldCutOffTime} on ${context.shouldCutOffDate}`}</Body3>
                        <Space size={[1, 0]} />
                      </>
                    )}
                    {context.isOrderDelivered && (
                      <Box display={['none', 'none', 'flex']} flexDirection={FlexDirection.Column}>
                        <Button
                          onClick={handleGetHelpClick}
                          colorVariant={ButtonColorVariant.Secondary}
                        >
                          Get help
                        </Button>
                      </Box>
                    )}
                  </>
                </Box>
              </Box>
            </Join>
          </Box>
        </Col>
        <Col size={[12, 12, 7]}>
          <RecipeImageTiles />
          {context.isOrderDelivered && (
            <Box display={['flex', 'flex', 'none']} flexDirection={FlexDirection.Column}>
              <Space size={4} />
              <Button onClick={handleGetHelpClick} colorVariant={ButtonColorVariant.Secondary}>
                Get help
              </Button>
            </Box>
          )}
          <Space size={[6, 6, 0]} />
          {context.isOrderDelivered ||
          context.isOrderCommitted ||
          context.isOrderOnPreMenu ? null : (
            <LinkWithoutUnderline href={`/menu/${orderId}`} onClick={handleSendEditRecipeTracking}>
              <Box display={[Display.Flex, Display.Flex, 'none']} alignItems={AlignItems.Center}>
                <Icon size={4} name="edit" variant={IconVariant.Actionable} />
                <Text size={4} fontWeight={700} color={Color.Secondary_400}>
                  Edit recipes
                </Text>
              </Box>
            </LinkWithoutUnderline>
          )}
        </Col>
        <Col size={2}>
          <Box
            display={['none', 'none', 'flex']}
            flexDirection={FlexDirection.Column}
            justifyContent={JustifyContent.SpaceBetween}
            alignItems={AlignItems.FlexEnd}
            height={'100%'}
          >
            <Join with={<Space size={6} direction="horizontal" />}>
              {context.isOrderDelivered ||
              context.isOrderCommitted ||
              context.isOrderOnPreMenu ? null : (
                <LinkWithoutUnderline href={`/menu/${orderId}`}>
                  <Box display={Display.Flex} alignItems={AlignItems.Center}>
                    <Icon size={4} name="edit" variant={IconVariant.Actionable} />
                    <Text size={4} fontWeight={700} color={Color.Secondary_400}>
                      Edit recipes
                    </Text>
                  </Box>
                </LinkWithoutUnderline>
              )}
              {context.isOrderOnPreMenu ? null : (
                <Box display={Display.Flex}>
                  <Box paddingRight={4}>
                    <DiscountPrice />
                  </Box>
                  <OrderDetailExpandButton
                    isOrderExpanded={isOrderExpanded}
                    setIsOrderExpanded={setIsOrderExpanded}
                    trackExpandOrder={trackExpandOrder}
                  />
                </Box>
              )}
            </Join>
            <div />
          </Box>
        </Col>
      </Grid>
      {isOrderExpanded && (
        <Box>
          <Space size={8} direction="vertical" />
          <Box
            height="1px"
            width="100%"
            borderStyle={BorderStyle.Solid}
            borderBottomWidth={0.5}
            borderColor={Color.ColdGrey_100}
          />
          <Box>
            <Space size={6} direction="vertical" />
            <OrderExpanded />
          </Box>
        </Box>
      )}
    </Box>
  )
}

import React, { useCallback } from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import GooglePayButton from '@google-pay/button-react'
import { Box } from '@gousto-internal/citrus-react'
import * as braintree from 'braintree-web'
import { useDispatch, useSelector } from 'react-redux'

import { isProd } from '@library/environment/isomorphic'

import { actionTypes } from 'actions/actionTypes'
import statusActions from 'actions/status'
import {
  checkoutSignup,
  fireCheckoutError,
  saveGooglePayDeliveryAddress,
  saveGooglePayPersonalInfo,
  saveGooglePayToken,
  setCurrentPaymentMethod,
  trackClickWithGooglePay,
  trackCanPayWithGooglePay,
} from 'routes/Checkout/checkoutActions'
import { hasCheckoutError } from 'routes/Checkout/checkoutSelectors'
import { PaymentMethod } from 'routes/Signup/signupConfig'
import { getBasketPostcode } from 'selectors/basket'

import { PRODUCTION, TEST } from './constants'
import { useGooglePayRequest } from './useGooglePayRequest'
import { parseGooglePayNonce, validateShipping } from './utils'

export function GooglePay({
  googlePayInstance,
  setIsReadyToPay,
}: {
  googlePayInstance: braintree.GooglePayment | null
  setIsReadyToPay: (result: boolean) => void
}) {
  const { loading, paymentRequest, pricing } = useGooglePayRequest(googlePayInstance)
  const dispatch = useDispatch()
  const hasErrors = useSelector(hasCheckoutError)
  const basketPostcode = useSelector(getBasketPostcode)
  const ENVIRONMENT: google.payments.api.Environment = isProd() ? PRODUCTION : TEST

  const handlePaymentAuthorized = useCallback<google.payments.api.PaymentAuthorizedHandler>(
    async (paymentData) => {
      if (!googlePayInstance) {
        datadogLogs.logger.error('handlePaymentAuthorized - google pay instance is null')

        return { transactionState: 'ERROR' }
      }

      trackClickWithGooglePay()

      dispatch(setCurrentPaymentMethod(PaymentMethod.GooglePay))

      dispatch({ type: actionTypes.GOOGLE_PAY_ERRORS_CLEAR })

      dispatch(statusActions.error(actionTypes.CHECKOUT_SIGNUP, null))
      dispatch(statusActions.error(actionTypes.USER_SUBSCRIBE, null))

      const result = await parseGooglePayNonce({ googlePayInstance, paymentData })
      try {
        if (!result) {
          dispatch(fireCheckoutError(actionTypes.GOOGLE_PAY_TOKEN_PARSING_ERROR))

          return { transactionState: 'ERROR' }
        }
        datadogRum.addAction('google_pay_session_started')

        dispatch(saveGooglePayDeliveryAddress(paymentData.shippingAddress))
        dispatch(saveGooglePayPersonalInfo(paymentData.shippingAddress))
        dispatch(saveGooglePayToken(result.nonce))

        await dispatch(checkoutSignup({ pricing }))

        return {
          transactionState: !hasErrors ? 'SUCCESS' : 'ERROR',
        }
      } catch (error: unknown) {
        /**
         * Any errors thrown by signup process will be captured by
         * <ExpressCheckout /> wrapper, returning transaction state
         * with ERROR will close the payment sheet and show error
         */
        datadogLogs.logger.error(
          'Error signing up GooglePay user',
          undefined,
          error instanceof Error ? error : new Error(String(error)),
        )

        return { transactionState: 'ERROR' }
      }
    },
    [googlePayInstance, dispatch, hasErrors, pricing],
  )

  const handlePaymentDataChanged = useCallback(
    (data: google.payments.api.IntermediatePaymentData) =>
      validateShipping({ intermediatePaymentData: data, postCode: basketPostcode }),
    [basketPostcode],
  )

  const handleReadyToPayChange = useCallback(
    (state: { isReadyToPay: boolean }) => {
      setIsReadyToPay(state.isReadyToPay)
      if (state.isReadyToPay) trackCanPayWithGooglePay()
      datadogRum.addAction(`can_pay_with_google_pay_${state.isReadyToPay}`)
    },
    [setIsReadyToPay],
  )

  if (loading || !paymentRequest) {
    return null
  }

  return (
    <Box data-testid="expressCheckoutGooglePay" width="100%">
      <GooglePayButton
        environment={ENVIRONMENT}
        paymentRequest={paymentRequest}
        buttonSizeMode="fill"
        buttonColor="black"
        buttonType="pay"
        buttonRadius={4}
        style={{ width: '100%', height: '48px' }}
        onPaymentAuthorized={handlePaymentAuthorized}
        onPaymentDataChanged={handlePaymentDataChanged}
        onReadyToPayChange={handleReadyToPayChange}
        existingPaymentMethodRequired
      />
    </Box>
  )
}

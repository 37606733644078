import { composeFetch, composeParser } from '@library/http'
import {
  addPath,
  auth,
  composeRequest,
  sendJSON,
  setMethod,
  setServiceUrl,
} from '@library/http/requests'
import { extractOrderResponseV2 } from './deserialisers'
import { OrderRequestV1 } from './interfaces'
import { extractCoreData } from '@library/http/src/parsers'

const deleteOrder = composeFetch(
  composeRequest<string | number>(
    setServiceUrl('order', 2),
    addPath((orderId) => `orders/${orderId}`),
    setMethod('DELETE'),
    auth,
  ),
  composeParser((basicResponse: Response) => {
    // Current delete endpoint responds with empty data nor parsed to json
    if (!basicResponse.ok) {
      throw new Error(basicResponse.statusText)
    }

    return true
  }),
)

const restoreCancelledOrder = composeFetch(
  composeRequest<string | number>(
    setServiceUrl('order', 2),
    addPath((orderId) => `orders/${orderId}/restore`),
    setMethod('POST'),
    auth,
  ),
  extractOrderResponseV2,
)

const updateOrderSlot = composeFetch(
  composeRequest<OrderRequestV1>(
    setServiceUrl('core'),
    addPath('order'),
    addPath(({ id: orderId }) => `${orderId}`),
    setMethod('PUT'),
    sendJSON(),
    auth,
  ),
  extractCoreData(),
)

export { deleteOrder, restoreCancelledOrder, updateOrderSlot }

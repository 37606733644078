import { connect } from 'react-redux'

import { redirect } from 'actions/redirect'
import {
  userGetReferralDetails,
} from 'actions/user'
import { ReferAFriendWrapper } from './ReferAFriend/ReferAFriendWrapper'

function mapStateToProps(state) {
  return {
    referralDetails: state.user.get('referralDetails'),
  }
}

const ReferAFriendContainer = connect(mapStateToProps, {
  userGetReferralDetails,
  redirect,
})(ReferAFriendWrapper)

export { ReferAFriendContainer }

import { SignupSteps } from 'routes/Signup/constants/SignupSteps'

export const PaymentMethod = {
  Card: 'Card',
  PayPal: 'PayPal',
  ApplePay: 'ApplePay',
  Bento: 'Bento',
  GooglePay: 'GooglePay',
}

const checkAccountPageSlug = 'start'
const applyVoucherPageSlug = 'apply-voucher'
const enterPromoCodeManuallyPageSlug = 'enter-discount-code'
const postcodeSlug = 'postcode'
const boxPriceSlug = 'box-size'
const dietaryPreferencesSlug = 'dietary-preferences'

export const signupConfig = {
  /**
   * If experiment steps should be added:
   * 1. Create another entry in signupConfig with listing appropriate steps (similar to signupConfig.defaultSteps).
   * 2. Add entries of new steps into signupConfig.steps array.
   * 3. Refer to new steps variable in "getSignupSteps.ts" file.
   * 4. Map step to component in "AvailableStepComponents.ts" file.
   */
  defaultSteps: [SignupSteps.BOX_SIZE, SignupSteps.POSTCODE, SignupSteps.DELIVERY],
  menuPersonalisationSteps: [
    SignupSteps.BOX_SIZE,
    SignupSteps.POSTCODE,
    SignupSteps.DELIVERY,
    SignupSteps.DIETARY_PREFERENCES,
    SignupSteps.RECIPE_PREFERENCES,
  ],
  steps: [
    { name: SignupSteps.BOX_SIZE, slug: 'box-size' },
    { name: SignupSteps.POSTCODE, slug: 'postcode' },
    { name: SignupSteps.DELIVERY, slug: 'delivery-options' },
    { name: SignupSteps.DIETARY_PREFERENCES, slug: 'dietary-preferences' },
    { name: SignupSteps.RECIPE_PREFERENCES, slug: 'recipe-preferences' },
  ],
  payment_types: {
    card: 'card',
    paypal: 'paypal',
  },
  address_types: {
    billing: 'billing',
    shipping: 'shipping',
  },
  boxSizeStep: {
    title: 'Choose your box size',
    discountApplied: 'Discount applied',
  },
  postCodeStep: {
    title: 'Where would you like your boxes delivered?',
    goustoOnDemandTitle: 'Where would you like your box delivered?',
    reminder: 'Delivered to your door, 7 days a week*',
    doNotDeliverErrorMessage: 'Sorry, it looks like we don’t currently deliver to your area.',
    genericErrorMessage: 'Please enter a valid postcode',
    footNoteMessage: '*Your available delivery days depend on your area',
    freeDeliveryMain: 'FREE delivery for a year',
    freeDeliveryTextPart1: 'Save up to',
    freeDeliveryTextPart2: '£200',
    freeDeliveryTextPart3: 'when you join!',
  },
  deliveryOptionsStep: {
    title: 'When would you like your first delivery?',
    goustoOnDemandTitle: 'When do you want your box delivered?',
  },
  discountAppliedText: 'View this when you reach the checkout.',
  sellThePropositionPageSlug: 'about',
  checkAccountPageSlug,
  applyVoucherPageSlug,
  enterPromoCodeManuallyPageSlug,
  canLandOnStepWithoutRedirecting: [
    checkAccountPageSlug,
    applyVoucherPageSlug,
    enterPromoCodeManuallyPageSlug,
    /**
     * Required for redirected to postcode from box prices.
     */
    postcodeSlug,
    boxPriceSlug,
    dietaryPreferencesSlug,
  ],
  maxSelectedRecipePreferences: 18,
  dietaryPreferences: [
    'vegetarian',
    'pescatarian',
    'plant-based',
    'dairy-free',
    'gluten-free',
    'no-seafood',
  ],
}

import React, { useCallback } from 'react'
import {
  Heading5,
  Box,
  Display,
  Image,
  Space,
  BorderStyle,
  Color,
  withCitrusTheme,
  JustifyContent,
} from '@gousto-internal/citrus-react'
import { browserCookies } from '@gousto-internal/storage-consent-web-package'

import { useChosenForYou } from '../hooks/useChosenForYou'
import { useChosenForYouTile } from '../hooks/useChosenForYouTile'
import { browserHistory } from 'react-router'
import { useTrackingContext } from '../hooks/useTrackingContext'
const EMPTY_UUID = '00000000-0000-0000-0000-000000000000'

export type Props = {
  orderId: string
  deliveryDate: string
  numPortions: string
}

const ImageContainer = withCitrusTheme(Image, () => ({
  objectFit: 'cover',
  width: '166px',
  height: '190.5px',
  cursor: 'pointer',
}))
const TabletImageContainer = withCitrusTheme(Image, () => ({
  objectFit: 'cover',
  width: '260px',
  height: '260px',
  cursor: 'pointer',
}))
const MobileImageContainer = withCitrusTheme(Image, () => ({
  objectFit: 'cover',
  width: '165px',
  height: '135px',
  cursor: 'pointer',
}))

export const ChosenForYouTile = ({ orderId, deliveryDate, numPortions }: Props) => {
  const [consented, sessionId] = browserCookies.getString('gousto_session_id')
  const { trackRecommendedRecipe } = useTrackingContext()
  const { recipes } = useChosenForYou({
    deliveryDate,
    numPortions: parseInt(numPortions),
    sessionId: consented ? sessionId || '' : EMPTY_UUID,
  })
  const imageDetailsArray = useChosenForYouTile(recipes)
  const handleClick = useCallback(
    (recipeId: string) => {
      browserHistory.push(`/menu/${orderId}`)

      trackRecommendedRecipe({
        recipe_id: recipeId,
      })
    },
    [orderId, trackRecommendedRecipe],
  )

  const renderMobileImages = () => {
    if (imageDetailsArray.length === 0) {
      return null
    }
    return (
      <>
        <MobileImageContainer
          key={imageDetailsArray[0]?.recipeId}
          src={imageDetailsArray[0]?.url}
          alt={imageDetailsArray[0]?.alt}
          width={`${imageDetailsArray[0]?.width}px`}
          onClick={() => handleClick(imageDetailsArray[0]?.recipeId)}
        />

        <Space size={2} direction={'horizontal'} />
        <MobileImageContainer
          key={imageDetailsArray[1]?.recipeId}
          src={imageDetailsArray[1]?.url}
          alt={imageDetailsArray[1]?.alt}
          width={`${imageDetailsArray[1]?.width}px`}
          onClick={() => handleClick(imageDetailsArray[1]?.recipeId)}
        />
      </>
    )
  }
  const renderTabletImages = () => {
    if (imageDetailsArray.length === 0) {
      return null
    }
    return (
      <>
        <TabletImageContainer
          key={imageDetailsArray[0]?.recipeId}
          src={imageDetailsArray[0]?.url}
          alt={imageDetailsArray[0]?.alt}
          width={`${imageDetailsArray[0]?.width}px`}
          onClick={() => handleClick(imageDetailsArray[0]?.recipeId)}
        />

        <Space size={2} direction={'horizontal'} />
        <TabletImageContainer
          key={imageDetailsArray[1]?.recipeId}
          src={imageDetailsArray[1]?.url}
          alt={imageDetailsArray[1]?.alt}
          width={`${imageDetailsArray[1]?.width}px`}
          onClick={() => handleClick(imageDetailsArray[1]?.recipeId)}
        />
      </>
    )
  }
  const renderImages = () => {
    if (imageDetailsArray.length === 0) {
      return null
    }
    return (
      <>
        <ImageContainer
          key={imageDetailsArray[0]?.recipeId}
          src={imageDetailsArray[0]?.url}
          alt={imageDetailsArray[0]?.alt}
          width={`${imageDetailsArray[0]?.width}px`}
          onClick={() => handleClick(imageDetailsArray[0]?.recipeId)}
        />

        <Space size={3} direction={'horizontal'} />
        <ImageContainer
          key={imageDetailsArray[1]?.recipeId}
          src={imageDetailsArray[1]?.url}
          alt={imageDetailsArray[1]?.alt}
          width={`${imageDetailsArray[1]?.width}px`}
          onClick={() => handleClick(imageDetailsArray[1]?.recipeId)}
        />
      </>
    )
  }
  const isRecipesEmpty = recipes?.length === 0

  return isRecipesEmpty ? null : (
    <>
      <Box
        paddingV={5}
        paddingH={5}
        borderStyle={BorderStyle.Solid}
        borderColor={Color.ColdGrey_100}
        borderWidth={0.5}
        display={['none', 'none', 'block']}
      >
        <Heading5>Top picks for you</Heading5>
        <Space size={4} />
        <Box>
          <Box display={Display.Flex} justifyContent={JustifyContent.Center}>
            {renderImages()}
          </Box>
        </Box>
      </Box>
      <Box paddingV={0} paddingH={0} display={['none', 'block', 'none']}>
        <Heading5>Top picks for you</Heading5>
        <Space size={1} />
        <Box>
          <Box display={Display.Flex} justifyContent={JustifyContent.Center}>
            {renderTabletImages()}
          </Box>
        </Box>
      </Box>
      <Box paddingV={0} paddingH={0} display={['block', 'none', 'none']}>
        <Heading5>Top picks for you</Heading5>
        <Space size={1} />
        <Box>
          <Box display={Display.Flex} justifyContent={JustifyContent.Center}>
            {renderMobileImages()}
          </Box>
        </Box>
      </Box>
    </>
  )
}

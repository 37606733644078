import useSWR from 'swr'
import { menuFetcherV3, MenuRecipe, MenuRequestParamsV3 } from '@library/menu-service'
import { ApiKeys, MENU_RECOMMENDED_CATEGORY_UUID } from '../configs'
import { fetchCurrentUser } from '@library/api-user'

type Response = {
  recipes: MenuRecipe[]
}

// formats iso date 2024-09-06 00:00:00 to 2024-09-06
const formatDate = (isoDate: string): string => {
  return isoDate.split(' ')[0]
}

export const useChosenForYou = (requestParams: MenuRequestParamsV3): Response => {
  const { data: userData } = useSWR(ApiKeys.UserCurrent, fetchCurrentUser)
  const { data } = useSWR(
    userData && userData.user
      ? {
          numPortions: requestParams.numPortions,
          deliveryDate: formatDate(requestParams.deliveryDate || ''),
          sessionId: requestParams.sessionId,
          userId: userData.user.authUserId,
        }
      : null,
    menuFetcherV3,
  )

  const hasRecomendedRecipes = data?.categories && data.categories[MENU_RECOMMENDED_CATEGORY_UUID]

  if (!hasRecomendedRecipes) {
    return {
      recipes: [],
    }
  }

  const categorizedRecipes = data.categories[MENU_RECOMMENDED_CATEGORY_UUID].recipes
  if (categorizedRecipes.length < 2) {
    return {
      recipes: [data.recipes[categorizedRecipes[0].id]],
    }
  }

  return {
    recipes: [data.recipes[categorizedRecipes[0].id], data.recipes[categorizedRecipes[1].id]],
  }
}

import { useEffect, useState } from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import * as braintree from 'braintree-web'

import { getGooglePayMerchantId } from '@library/environment/isomorphic'

import { usePricing } from 'routes/Menu/domains/pricing'

import { transformFullRequest } from './utils'

export function useGooglePayRequest(googlePayInstance: braintree.GooglePayment | null) {
  const [loading, setLoading] = useState(true)
  const { pricing } = usePricing()
  const GOOGLE_PAY_MERCHANT_ID = getGooglePayMerchantId()

  const [paymentRequest, setPaymentRequest] =
    useState<google.payments.api.PaymentDataRequest | null>(null)

  useEffect(() => {
    const fetchPaymentDataRequest = async () => {
      if (googlePayInstance) {
        try {
          const baseRequest = await googlePayInstance.createPaymentDataRequest({
            merchantInfo: {
              merchantId: GOOGLE_PAY_MERCHANT_ID,
            },
            transactionInfo: {
              totalPriceStatus: 'FINAL',
              totalPrice: pricing?.total as string,
              currencyCode: 'GBP',
            },
          })

          const transformedRequest = transformFullRequest(baseRequest)
          setPaymentRequest(transformedRequest)
        } catch (error) {
          datadogLogs.logger.error(
            `Error creating GooglePay payment request ${JSON.stringify(error)}`,
          )
        } finally {
          setLoading(false)
        }
      }
    }
    fetchPaymentDataRequest()
  }, [GOOGLE_PAY_MERCHANT_ID, googlePayInstance, pricing?.total])

  return { loading, paymentRequest, pricing }
}

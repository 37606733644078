import { actionTypes } from 'actions/actionTypes'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'

export const saveGooglePayPersonalInfo: CheckoutActionCreator = ({
  name,
  phoneNumber,
}: google.payments.api.Address) => {
  const fullName = name || ''
  const [firstName = '', lastName = ''] = fullName.split(' ')

  return {
    type: actionTypes.SAVE_GOOGLE_PAY_PERSONAL_INFO,
    personalInfo: {
      firstName,
      lastName,
      phoneNumber,
    },
  }
}

export type SignupDiscount = {
  amount: number
  recurrence: number
}

export type BoxPrice = {
  price: number
  discounts: SignupDiscount[]
}

export type BoxPrices = {
  [boxType: string]: BoxPrice
}

// Recurrence is the number of orders that the discount applies to.
// Needs to add up to the total number of orders, which is calculated as
// ORDER_FREQUENCY multiplied by PROMOTION_DURATION.
const discountStages: SignupDiscount[] = [
  { amount: 50, recurrence: 1 },
  { amount: 40, recurrence: 1 },
  { amount: 20, recurrence: 6 },
]

export const PROMOTION_DURATION = 2 // months
export const ORDER_FREQUENCY = 4 // orders per month

export const boxPrices: BoxPrices = {
  '2x1': {
    price: 18.99,
    discounts: discountStages,
  },
  '3x1': {
    price: 23.99,
    discounts: discountStages,
  },
  '4x1': {
    price: 28.99,
    discounts: discountStages,
  },
  '5x1': {
    price: 33.99,
    discounts: discountStages,
  },
  '2x2': {
    price: 25.99,
    discounts: discountStages,
  },
  '3x2': {
    price: 30.99,
    discounts: discountStages,
  },
  '4x2': {
    price: 35.99,
    discounts: discountStages,
  },
  '5x2': {
    price: 43.99,
    discounts: discountStages,
  },
  '2x3': {
    price: 30.99,
    discounts: discountStages,
  },
  '3x3': {
    price: 40.99,
    discounts: discountStages,
  },
  '4x3': {
    price: 49.99,
    discounts: discountStages,
  },
  '5x3': {
    price: 58.99,
    discounts: discountStages,
  },
  '2x4': {
    price: 32.75,
    discounts: discountStages,
  },
  '3x4': {
    price: 43.75,
    discounts: discountStages,
  },
  '4x4': {
    price: 50.99,
    discounts: discountStages,
  },
  '5x4': {
    price: 59.8,
    discounts: discountStages,
  },
  '2x5': {
    price: 42.99,
    discounts: discountStages,
  },
  '3x5': {
    price: 54.99,
    discounts: discountStages,
  },
}

import { createContext, useContext } from 'react'
import { UseOrderTileResult } from './useOrderTile'

export const OrderTileContext = createContext<UseOrderTileResult['data'] | null>(null)

OrderTileContext.displayName = 'OrderTileContext'

export const useOrderTileContext = () => {
  const hook = useContext(OrderTileContext)

  if (hook === null) {
    throw new Error('OrderTileContext value is null')
  }

  return hook
}

import {
  getBillingAddress,
  getIsBillingAddressDifferent,
  isApplePayPayment,
  isCardPayment,
  isGooglePayPayment,
} from 'routes/Checkout/checkoutPaymentSelectors'
import {
  getApplePayDeliveryAddress,
  getApplePayPersonalInfo,
  getDeliveryAddress,
  getDeliveryInstruction,
  getEmail,
  getFormattedPhoneNumber,
  getGooglePayDeliveryAddress,
  getGooglePayPersonalInfo,
  getIsCompetitionsAndSurveysEnabled,
  getIsEmailMarketingEnabled,
  getName,
  getPasswordValue,
  getPhone,
} from 'routes/Checkout/checkoutSelectors'
import { getAddress } from 'routes/Checkout/checkoutUtils'
import { CheckoutStore } from 'routes/Checkout/models/CheckoutStore'
import { signupConfig } from 'routes/Signup/signupConfig'
import { getPreviewOrderId, getPromoCode } from 'selectors/basket'
import { getSessionId } from 'selectors/cookies'
import type { DeliveryDetailsStore } from 'selectors/deliveryDetails'
import {
  getDeliveryDetailsInstructions,
  getDeliveryDetailsInstructionsCustom,
} from 'selectors/deliveryDetails'
import { getIsGoustoOnDemandEnabled } from 'selectors/features'

type CheckoutDelivery = CheckoutStore & DeliveryDetailsStore

export const buildSignupRequestData = (state: CheckoutDelivery) => {
  const { basket, promoAgeVerified, checkout } = state

  const isApplePay = isApplePayPayment(state)
  const isGooglePay = isGooglePayPayment(state)
  const applePayPersonalInfo = getApplePayPersonalInfo(state)
  const googlePayPersonalInfo = getGooglePayPersonalInfo(state)

  const orderId = getPreviewOrderId(state)
  const promoCode = getPromoCode(state) || ''
  const sessionId = getSessionId()

  const tariffId = basket.get('tariffId', '')

  const getPhoneNumber = () => {
    if (isApplePay) return getFormattedPhoneNumber(applePayPersonalInfo.phoneNumber)
    if (isGooglePay) return getFormattedPhoneNumber(googlePayPersonalInfo.phoneNumber)

    return getFormattedPhoneNumber(getPhone(state))
  }

  const phoneNumber = getPhoneNumber()

  const email = getEmail(state)
  const personalInfo = getName(state)

  const getFirstName = () => {
    if (isApplePay) return applePayPersonalInfo.firstName
    if (isGooglePay) return googlePayPersonalInfo.firstName

    return personalInfo.firstName.trim()
  }

  const firstName = getFirstName()

  const getLastName = () => {
    if (isApplePay) return applePayPersonalInfo.lastName
    if (isGooglePay) return googlePayPersonalInfo.lastName

    return personalInfo.lastName.trim()
  }

  const lastName = getLastName()

  const password = getPasswordValue(state)
  const ageVerified = Number(promoAgeVerified || false)
  const allowEmail = Number(getIsEmailMarketingEnabled(state) || false)
  const allowThirdParty = Number(getIsCompetitionsAndSurveysEnabled(state) || false)
  const goustoRef = checkout.get('goustoRef')
  const isBillingAddressDifferent = getIsBillingAddressDifferent(state) || false

  const { deliveryInstruction, deliveryInstructionCustom } = getDeliveryInstruction(state)

  const getDeliveryDetails = () => {
    if (isApplePay) {
      return getDeliveryDetailsInstructionsCustom(state) || getDeliveryDetailsInstructions(state)
    }
    if (isGooglePay) {
      return getDeliveryDetailsInstructionsCustom(state) || getDeliveryDetailsInstructions(state)
    }

    return deliveryInstructionCustom || deliveryInstruction
  }

  const deliveryInstructions = getDeliveryDetails()
  const addressFields = getDeliveryAddress(state)
  const billingAddressFields = getBillingAddress(state)

  const getDeliveryAddressDetails = () => {
    if (isApplePay) return getApplePayDeliveryAddress(state)
    if (isGooglePay) return getGooglePayDeliveryAddress(state)

    return getAddress(addressFields)
  }

  const deliveryAddress = getDeliveryAddressDetails()

  const billingAddress =
    isCardPayment(state) && isBillingAddressDifferent
      ? getAddress(billingAddressFields)
      : deliveryAddress

  const deliverySlotId = basket.get('slotId')
  const boxId = basket.get('boxId')

  const isGoustoOnDemandEnabled = getIsGoustoOnDemandEnabled(state)
  const subscription = isGoustoOnDemandEnabled
    ? { paused: Number(isGoustoOnDemandEnabled || false) }
    : {}

  return {
    order_id: orderId,
    promocode: promoCode,
    session_id: sessionId,
    customer: {
      tariff_id: tariffId,
      phone_number: phoneNumber,
      email,
      name_first: firstName,
      name_last: lastName,
      promo_code: promoCode,
      password,
      age_verified: ageVerified,
      marketing_do_allow_email: allowEmail,
      marketing_do_allow_thirdparty: allowThirdParty,
      gousto_ref: goustoRef,
    },
    addresses: {
      shipping_address: {
        type: signupConfig.address_types.shipping,
        delivery_instructions: deliveryInstructions,
        ...deliveryAddress,
      },
      billing_address: {
        type: signupConfig.address_types.billing,
        ...billingAddress,
      },
    },
    subscription: {
      interval_id: 1,
      delivery_slot_id: deliverySlotId,
      box_id: boxId,
      ...subscription,
    },
    decoupled: {
      payment: 1,
    },
  }
}

import React from 'react'

import { useDecision } from '@optimizely/react-sdk'

import { MyGoustoPage } from '@features/my-gousto-page'

import { MyGoustoContainer } from './MyGoustoContainer'
import { ReferAFriendContainer } from './ReferAFriendContainer'

const MyGoustoOptimizely = () => {
  const [MyGoustoPageABTestOFXResult] = useDecision('rockets_ofx_my_gousto_v2')
  const isMyGoustoPageABTestEnabled = MyGoustoPageABTestOFXResult.variationKey === 't1'

  if (!isMyGoustoPageABTestEnabled) {
    return (
      <div data-testid="my-gousto-v1">
        <MyGoustoContainer />
      </div>
    )
  }

  return (
    <div data-testid="my-gousto-v2">
      <MyGoustoPage />
      <ReferAFriendContainer />
    </div>
  )
}

export { MyGoustoOptimizely }

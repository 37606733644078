import PropTypes from 'prop-types'
import React from 'react'
import moment from 'moment'
import config from 'config'
import * as trackingKeys from 'actions/trackingKeys'
import { client as clientRoutes } from 'config/routes'
import { onEnter } from 'utils/accessibility'
import { AppStoreLinks } from 'components/AppStoreLinks'
import { Box, LinkVariant } from '@gousto-internal/citrus-react'
import Link from 'Link'
import UKTaxStrategyPdf from './assets/UK_Tax_Strategy.pdf'
import { FooterNotice } from './FooterNotice'
import css from './Footer.css'

const showHelpPreLogin = (helpPreLoginVisibilityChange) => (
  helpPreLoginVisibilityChange(true)
)

const renderHelpLink = (
  isAuthenticated,
  helpPreLoginVisibilityChange,
  trackNavigationClick
) => (
  isAuthenticated
    ? (
      <Link
        to={clientRoutes.helpCentre}
        data-optimizely="footer-help-link"
        data-testid="footer-learn-more"
        title="Help"
        clientRouted={false}
        secondary
        tracking={() => trackNavigationClick({
          actionType: trackingKeys.clickHelpFooter,
          seCategory: 'help',
          logged_in: true,
        })}
      >
        Help
      </Link>
    )
    : (
      <span
        data-test="help-link"
        role="button"
        tabIndex="0"
        onClick={() => {
          showHelpPreLogin(helpPreLoginVisibilityChange)
          trackNavigationClick({
            actionType: trackingKeys.clickHelpFooter,
            seCategory: 'help',
            logged_in: false,
          })
        }}
        onKeyDown={onEnter(() => showHelpPreLogin(helpPreLoginVisibilityChange))}
      >
        Help
      </span>
    )
)

export const Footer = ({
  copyright,
  helpPreLoginVisibilityChange,
  isAuthenticated,
  simple,
  type,
  trackNavigationClick,
  isOnLandingPage,
}) => {
  const renderTermsLink = () => (
    <li className={css.menuItem}>
      Terms
      (
      <Link to={clientRoutes.termsOfUse} data-testid="footer-terms-of-use" title="Terms &amp; Conditions" clientRouted={false} secondary>Website</Link>
      )
      (
      <Link to={clientRoutes.termsAndConditions} data-testid="footer-terms-and-conditions" title="Terms &amp; Conditions for Sale of Goods" clientRouted={false} secondary>Sale</Link>
      )
    </li>
  )

  const renderPrivacyLink = () => (
    <li className={css.menuItem}>
      <Link to={clientRoutes.privacyPolicy} data-testid="footer-privacy-statement" title="Privacy Policy" clientRouted secondary>Privacy and Cookies Policies</Link>
    </li>
  )

  const renderModernSlaveryLink = () => (
    <li className={css.menuItem}>
      <Link to={clientRoutes.modernSlavery} data-testid="footer-modern-slavery-statement" title="Modern Slavery" clientRouted secondary>
        Modern Slavery
        <span className={css.modernSlaveryLink}> Statement</span>
      </Link>
    </li>
  )

  const renderSimpleList = () => (
    <ul className={css.menuList}>
      {renderTermsLink()}
      {renderPrivacyLink()}
      {renderModernSlaveryLink()}
    </ul>
  )

  const trackWeeklyRecipesClick = () => trackNavigationClick({ actionType: trackingKeys.clickRecipeNavigationFooter })
  const renderFullList = () => (
    <ul className={css.menuList}>
      <li className={css.menuItem}>
        <Link to={clientRoutes.home} data-testid="footer-home" title="Home" clientRouted={false} secondary>Home</Link>
      </li>
      <li className={css.menuItem}>
        {/* eslint-disable-next-line */}
        <span data-test="week-recipes" onClick={trackWeeklyRecipesClick} role="button" tabIndex={0}>
          <Link
            to={clientRoutes.menu}
            data-testid="footer-this-weeks-recipes"
            title="This Week's Recipes"
            clientRouted
            secondary
          >
            This Week&apos;s Recipes
          </Link>
        </span>
      </li>
      <li className={css.menuItem}>
        {renderHelpLink(isAuthenticated, helpPreLoginVisibilityChange, trackNavigationClick)}
      </li>
      {renderTermsLink()}
      <li className={css.menuItem}>
        <Link data-testid="footer-cookbook" to={clientRoutes.cookbook} title="Cookbook" clientRouted={false} secondary>Cookbook</Link>
      </li>
      <li className={css.menuItem}>
        <Link to={clientRoutes.jobs} data-testid="footer-jobs" title="Jobs" clientRouted={false} secondary>Jobs</Link>
      </li>
      <li className={css.menuItem}>
        <Link to={clientRoutes.weCare} data-testid="footer-we-care" title="We Care" clientRouted={false} secondary>We Care</Link>
      </li>
      <li className={css.menuItem}>
        <Link to={clientRoutes.blog} data-testid="footer-blog" title="Blog" clientRouted={false} secondary>Blog</Link>
      </li>
      <li className={css.menuItem}>
        <Link to={clientRoutes.studentDiscount} data-testid="footer-student-discount" title="Student &amp; Graduate Discount" clientRouted={false} secondary>Student &amp; Graduate Discount</Link>
      </li>
      {renderPrivacyLink()}
      {renderModernSlaveryLink()}
      <li className={css.menuItem}>
        <Link to={UKTaxStrategyPdf} data-testid="footer-uk-tax-strategy" title="UK Tax Strategy" clientRouted={false} secondary>UK Tax Strategy</Link>
      </li>
      <li className={css.menuItem}>
        <Link to={clientRoutes.accessibilityStatement} data-testid="footer-accessibility-statement" title="Accessibility Statement" clientRouted secondary>
          Accessibility Statement
        </Link>
      </li>
      <li className={css.menuItem}>
        <Link to={clientRoutes.ourSuppliers} data-testid="footer-our-suppliers" title="Our Suppliers" clientRouted={false} secondary>
          Our Suppliers
        </Link>
      </li>
    </ul>
  )

  const renderSocial = () => (
    <div className={css.appLinks}>
      <ul className={css.links}>
        <li className={css.link}>
          <Link to="http://www.facebook.com/goustocooking" data-testid="footer-facebook" clientRouted={false} secondary>
            <span role="img" aria-label="facebook" className={css.facebook} />
          </Link>
        </li>
        <li className={css.link}>
          <Link to="https://twitter.com/goustocooking" data-testid="footer-twitter" clientRouted={false} secondary>
            <span role="img" aria-label="twitter" className={css.twitter} />
          </Link>
        </li>
        <li className={css.link}>
          <Link to="https://www.youtube.com/UKGousto/" data-testid="footer-youtube" clientRouted={false} secondary>
            <span role="img" aria-label="youtube" className={css.youtube} />
          </Link>
        </li>
        <li className={css.link}>
          <Link to="http://instagram.com/goustocooking" data-testid="footer-instagram" clientRouted={false} secondary>
            <span role="img" aria-label="instagram" className={css.instagram} />
          </Link>
        </li>
      </ul>
      <AppStoreLinks appStoreId={config.apps.appStoreId} playStoreId={config.apps.playStoreId} />
    </div>
  )

  const copyrightText = (
    <p>
      &copy; Gousto
      {moment().format('YYYY')}
      . All rights reserved.
    </p>
  )

  const renderCopyright = () => (
    <div id="copyright" className={css.copyright}>
      {copyrightText}
    </div>
  )

  const renderDeliveryNotice = () => (
    <FooterNotice>
      * New customers only. {' '}
      <Link href="https://www.gousto.co.uk/terms-and-conditions" variant={LinkVariant.Link}>
        Standard T&Cs apply.
      </Link> Not including additional charges. Offer may only be claimed once and applies to the account you are logged into. Once discount is claimed, the relevant discount will be applied to your first order and subsequent discount to all following orders delivered within 60 days. This offer cannot be used in conjunction with other offers.
      <br />
      <br />
      † £2.99 per meal is based on a standard 5 recipe box for 4 people with a subscription, excluding £3.99 delivery
    </FooterNotice>
  )

  const renderSimpleFooter = () => (
    <div>
      {renderSimpleList()}
      {renderCopyright()}
    </div>
  )

  const renderMediumFooter = () => (
    <Box paddingBottom={[30, 0]}>
      {renderSocial()}
      {renderFullList()}
      {copyright && renderCopyright()}
      {isOnLandingPage && renderDeliveryNotice()}
    </Box>
  )

  const renderLargeFooter = () => (
    <div>
      {renderMediumFooter()}
    </div>
  )

  const renderFooter = () => {
    let footer = null

    if (simple) {
      // Back compatibility of simple prop
      return renderSimpleFooter()
    }

    switch (type) {
    case 'simple':
      footer = renderSimpleFooter()
      break
    case 'medium':
      footer = renderMediumFooter()
      break
    case 'large':
      footer = renderLargeFooter()
      break
    default: {
      footer = renderMediumFooter()
      break
    }
    }

    return footer
  }

  return (
    <footer className={css.footer}>
      {renderFooter()}
    </footer>
  )
}

Footer.propTypes = {
  copyright: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  helpPreLoginVisibilityChange: PropTypes.func.isRequired,
  simple: PropTypes.bool,
  type: PropTypes.string,
  trackNavigationClick: PropTypes.func.isRequired,
  isOnLandingPage: PropTypes.bool,
}

Footer.defaultProps = {
  copyright: true,
  isAuthenticated: false,
  simple: false,
  type: 'medium',
  isOnLandingPage: false,
}

import { createContext, useContext } from 'react'
import { UseOrderTracking } from './useOrderTracking'

export const TrackingContext = createContext<UseOrderTracking | null>(null)

TrackingContext.displayName = 'TrackingContext'

export const useTrackingContext = () => {
  const hook = useContext(TrackingContext)

  if (hook === null) {
    throw new Error('TrackingContext value is null')
  }

  return hook
}

import { useCallback, useState } from 'react'
import { useDays } from './useDays'
import { useOrderTileContext } from './useOrderTileContext'
import { useSWRConfig } from 'swr'
import { deleteOrder, OrderID, OrderRequestV1, updateOrderSlot } from '@library/api-order'
import { ApiKeys } from '../configs'
import { useTrackingContext } from './useTrackingContext'
import { datadogLogs } from '@datadog/browser-logs'

export const useOrderExpand = () => {
  const context = useOrderTileContext()
  const { trackCancelOrder } = useTrackingContext()
  const [isUpdatingDeliveryDate, setIsUpdatingDeliveryDate] = useState<boolean>(false)
  const [shouldDeleteOrder, setShouldDeleteOrder] = useState<boolean>(false)
  const [showEditDelivery, setShowEditDelivery] = useState<boolean>(false)

  const { mutate } = useSWRConfig()

  const makeUpdateSlotRequest = useCallback(
    async (params: OrderRequestV1) => {
      setIsUpdatingDeliveryDate(true)

      try {
        await updateOrderSlot(params)

        mutate(ApiKeys.UserCurrentOrders)
      } catch (error) {
        // TODO: Add error to the UI
        datadogLogs.logger.error(
          `Error updating delivery date for order ${params.id} ${JSON.stringify(error)}`,
        )
      } finally {
        setIsUpdatingDeliveryDate(false)
      }
    },
    [mutate],
  )

  const makeDeleteOrderRequest = useCallback(
    async (orderId: OrderID) => {
      setShouldDeleteOrder(true)

      try {
        await deleteOrder(orderId)

        trackCancelOrder()

        mutate(ApiKeys.UserCurrentOrders)
      } catch (error) {
        // TODO: Add error to the UI
        datadogLogs.logger.error(`Error deleting order ${orderId} ${JSON.stringify(error)}`)
      } finally {
        setShouldDeleteOrder(false)
      }
    },
    [mutate, trackCancelOrder],
  )

  // 2024-11-16 00:00:00 -> 2024-11-16
  const deliveryDate = String(context.deliveryDate).split(' ')[0]
  // Monday 08:00:00 - 19:00:00 -> 08:00:00 - 19:00:00
  const humanDeliveryTime = context.humanDeliveryDayTime.replace(/^\w+\s/, '')

  const { availableSlots, isLoading, selectedSlot, setSelectedDaySlotLeadTimeId, setDeliveryDate } =
    useDays(
      context.recipeItems,
      context.box.numPortions,
      context.period.start,
      context.period.end,
      context.daySlotLeadTimeId,
      deliveryDate,
    )

  const selectedDaySlotLeadTime = selectedSlot?.daySlotLeadTimes
    ? selectedSlot.daySlotLeadTimes.find((slot) => slot.isSelected) ||
      selectedSlot.daySlotLeadTimes[0]
    : null

  const handleSelectDate = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDeliveryDate(event.target.value)
  }

  const handleSelectTime = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDaySlotLeadTimeId(event.target.value)
  }

  const handleEditClick = () => {
    setShowEditDelivery(!showEditDelivery)
  }

  const handleCancelDelivery = () => {
    makeDeleteOrderRequest(context.orderId as OrderID)
  }

  const handleUpdateSlotClick = () => {
    makeUpdateSlotRequest({
      id: context.orderId as OrderID,
      delivery_day_id: selectedSlot?.coreDayId || '',
      delivery_slot_id: selectedDaySlotLeadTime?.coreSlotId || '',
      day_slot_lead_time_id: selectedDaySlotLeadTime?.id || '',
    })
  }

  return {
    availableSlots,
    context,
    handleSelectDate,
    handleSelectTime,
    handleEditClick,
    handleCancelDelivery,
    handleUpdateSlotClick,
    humanDeliveryTime,
    isLoading,
    isUpdatingDeliveryDate,
    showEditDelivery,
    selectedSlot,
    shouldDeleteOrder,
  }
}

import { composeFetch } from '@library/http'
import {
  addPath,
  auth,
  composeRequest,
  setMethod,
  setQueryParam,
  setServiceUrl,
  sendJSON,
  setHost,
  } from '@library/http/requests'
import { extractCoreData } from '@library/http/parsers'
import type {
  AddressID,
  CurrentUser,
  UserBalance,
  UserChangeDefaultAddressBody,
  UserEmailUpdatePayload,
  UserRequestBody,
  ValidateEmailPayload,
  ValidateEmailResponse,
  } from './interfaces'
  import { useAddressSerialiser, useChangeDefaultAddressSerialiser } from './serialisers'
  import {
      extractAddressCreatedData,
      extractAddressUpdatedData,
      extractEmptyResponse,
      extractPendingOrders,
      extractShippingAddressData,
      extractUserOrders,
      extractUserResponse,
    } from './deserialisers'

const isTestingEnvironment = process.env.NODE_ENV === 'test'

const currentUserRequest = composeRequest(setServiceUrl('core'), addPath('user/current'), auth)

export const fetchCurrentUser = composeFetch(currentUserRequest, extractCoreData<CurrentUser>())

export const fetchUserShippingAddresses = composeFetch(
  composeRequest(currentUserRequest, addPath('address'), setQueryParam('type', 'shipping')),
  extractShippingAddressData,
)

export const createShippingAddress = composeFetch(
  composeRequest(
    currentUserRequest,
    addPath('address/shipping'),
    setMethod('POST'),
    useAddressSerialiser,
  ),
  extractAddressCreatedData,
)

export const fetchUserBalance = composeFetch(
  composeRequest(currentUserRequest, addPath('balance')),
  extractCoreData<UserBalance>(),
)

export const resetUserPassword = composeFetch(
  composeRequest(currentUserRequest, addPath('passwordReset'), setMethod('POST')),
  extractCoreData<void>(),
)

export const updateCurrentUser = composeFetch(
  composeRequest(currentUserRequest, setMethod('PUT'), sendJSON<Partial<UserRequestBody>>()),
  extractUserResponse,
)

export const updateUserEmail = composeFetch(
  composeRequest(
    setServiceUrl('core'),
    addPath('v2/user/current'),
    setMethod('PATCH'),
    sendJSON<UserEmailUpdatePayload>(),
    auth,
  ),
  extractUserResponse,
)

export const deleteUserAccount = composeFetch(
  composeRequest(currentUserRequest, setMethod('DELETE')),
  extractCoreData<void>(),
)

export const logoutUser = composeFetch(
  composeRequest(
    isTestingEnvironment ? setServiceUrl('core') : setHost(''),
    addPath('logout'),
    setMethod('POST'),
    auth,
  ),
  extractEmptyResponse,
)

export const forgetUser = composeFetch(
  composeRequest(
    isTestingEnvironment ? setServiceUrl('core') : setHost(''),
    addPath('forget'),
    setMethod('POST'),
    sendJSON<{ accessToken: string }>(),
  ),
  extractEmptyResponse,
)

export const updateUserDefaultAddress = composeFetch(
  composeRequest(
    currentUserRequest,
    addPath('changeDefaultAddress'),
    setMethod('PUT'),
    sendJSON<UserChangeDefaultAddressBody>(),
    useChangeDefaultAddressSerialiser,
  ),
  extractCoreData<void>(),
)

export const deleteShippingAddress = composeFetch(
  composeRequest(
    currentUserRequest,
    addPath((addressId: AddressID) => `address/${addressId}`),
    setMethod('DELETE'),
  ),
  extractCoreData<void>(),
)

export const fetchUserPendingOrders = composeFetch(
  composeRequest(
    currentUserRequest,
    addPath('orders'),
    setQueryParam('includes', 'shipping_address'),
    setQueryParam('limit', '1000'), // weekly subscription over ~20 years
  ),
  extractPendingOrders,
)

export const fetchUserOrders = composeFetch(
  composeRequest(
    currentUserRequest,
    addPath('orders'),
    setQueryParam('state', 'pending'),
    setQueryParam('sort_order', 'desc'),
    setQueryParam('limit', '20'),
    setQueryParam('includes[0]', 'shipping_address'),
    setQueryParam('extends[0]', 'restorable'),
  ),
  extractUserOrders,
)

export const updateShippingAddress = composeFetch(
  composeRequest(
    currentUserRequest,
    addPath((values) => `address/${values.id}`),
    setMethod('PUT'),
    useAddressSerialiser,
  ),
  extractAddressUpdatedData,
)

export const validateEmail = composeFetch(
  composeRequest(
    setServiceUrl('core'),
    addPath('user/validate-email'),
    setMethod('POST'),
    sendJSON<ValidateEmailPayload>(),
  ),
  extractCoreData<ValidateEmailResponse>(),
)

import React from 'react'

import {
  Body2,
  Box,
  Join,
  Link,
  LinkVariant,
  Space,
  TextAlign,
} from '@gousto-internal/citrus-react'

import config from 'config/routes'
import { useIsDiscountGiftEnabled } from 'hooks/useIsDiscountGiftEnabled'

export const discountGiftTerms =
  '**Offer valid for new UK customers only (including Northern Ireland), excluding Jersey, Guernsey, Isle of Man, Scottish Highlands and Islands. Offer ends 28/10/2024. The offer entitles you to 60% off your first box and 20% off all subsequent boxes for two months from the date of sign up. The offer does not include surcharge amounts from premium recipes, Gousto Marketplace items or delivery fees. You will also receive a free 4 x 50g pack of Pots & Co Little Pots 70% Chocolate Ganache with each order for three months from the date of sign up. You will only be entitled to a maximum of 12 packs during the three month period. Cannot cater for dietary requirements. In the event Gousto cannot supply a dessert for any reason, you will need to contact Gousto Customer Care via the methods listed in the help centre to receive a Pots & Co credit voucher. The offer cannot be used in conjunction with any other offers. You will be enrolled into a flexible subscription once the offer is redeemed (skip/pause/cancel at any time for free). See the website for full T&Cs.'

interface Props {
  children: React.ReactNode
}

export const FooterNotice = ({ children }: Props) => {
  const isDiscountGiftingExperimentEnabled = useIsDiscountGiftEnabled()

  return (
    <Box
      paddingH={[3, 8, 8, 8]}
      paddingV={0}
      paddingBottom={4}
      maxWidth={['100%', '60rem', '60rem', '60rem']}
      style={{ margin: 'auto' }}
    >
      <Join with={<Space size={2} />}>
        <Body2>{children}</Body2>

        <Body2 textAlign={TextAlign.Center}>
          ‡ To verify our carbon footprint claims, click&nbsp;
          <Link href={config.client.carbonFootprintComparison} variant={LinkVariant.None}>
            here
          </Link>
        </Body2>

        {isDiscountGiftingExperimentEnabled && (
          <Body2 textAlign={TextAlign.Center}>{discountGiftTerms}</Body2>
        )}
      </Join>
    </Box>
  )
}

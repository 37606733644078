import { queueTrackingEvent } from '@library/tracking-integration'

export type UseTracking = {
  trackViewAllUpcomingDeliveries: () => void
}

type TrackingEventsForViewAllUpcomingDeliveries = {
  action: 'view_all_upcoming_deliveries'
}

export const useTracking = (): UseTracking => {
  return {
    trackViewAllUpcomingDeliveries: () => {
      queueTrackingEvent<TrackingEventsForViewAllUpcomingDeliveries>({
        action: 'view_all_upcoming_deliveries',
      })
    },
  }
}

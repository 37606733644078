import { actionTypes } from 'actions/actionTypes'
import { CheckoutActionCreator } from 'routes/Checkout/models/ReduxTypes'

export const saveGooglePayDeliveryAddress: CheckoutActionCreator = ({
  address1,
  address2,
  address3,
  administrativeArea,
  postalCode,
  locality,
}: google.payments.api.Address) => ({
  type: actionTypes.SAVE_GOOGLE_PAY_DELIVERY_ADDRESS,
  deliveryAddress: {
    name: 'My Address',
    line1: address1,
    line2: address2,
    line3: address3 ?? '',
    town: locality,
    county: administrativeArea,
    postcode: postalCode,
  },
})

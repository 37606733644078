import { getRecipeAvailabilityByDeliveryDateRange } from '@library/api-recipeavailability'
import useSWR from 'swr'

type DeliveryDate = string
type RecipeId = string
type PortionAvailable = number

export type RecipeAvailabilityMap = Record<
  DeliveryDate,
  Record<RecipeId, Record<PortionAvailable, boolean>>
>

type Portions = Record<PortionAvailable, { available: boolean }>

export type RecipeAvailability = {
  id: string
  recipeId: string
  deliveryDate: string
  portions: Portions
}

const transformRecipeAvailabilityArrayToMap = (
  recipeAvailability: RecipeAvailability[] | undefined,
) => {
  return recipeAvailability?.reduce<RecipeAvailabilityMap>((recipeAvailabilityMap, recipe) => {
    const portionsAvailable = Object.entries(recipe.portions).reduce<
      Record<PortionAvailable, boolean>
    >((portionsAvailableMap, portions) => {
      const [portion, { available }] = portions
      portionsAvailableMap[parseInt(portion)] = available

      return portionsAvailableMap
    }, {})

    if (!recipeAvailabilityMap[recipe.deliveryDate]) {
      recipeAvailabilityMap[recipe.deliveryDate] = {}
    }

    recipeAvailabilityMap[recipe.deliveryDate][recipe.recipeId] = portionsAvailable

    return recipeAvailabilityMap
  }, {})
}

export const useRecipeAvailability = (
  deliveryDateStart: string,
  deliveryDateEnd: string,
  recipesCount = 1,
) => {
  const shouldFetch = deliveryDateStart.length > 0 && deliveryDateEnd.length > 0 && recipesCount > 0

  const response = useSWR(
    shouldFetch
      ? {
          deliveryDateFrom: deliveryDateStart,
          deliveryDateUntil: deliveryDateEnd,
        }
      : false,
    getRecipeAvailabilityByDeliveryDateRange,
  )

  if (!response?.data) {
    return {
      recipeAvailabilityMap: undefined,
      isLoading: true,
    }
  }

  if (response?.error) {
    return {
      recipeAvailabilityMap: undefined,
      isLoading: false,
    }
  }

  return {
    recipeAvailabilityMap: transformRecipeAvailabilityArrayToMap(response.data),
    isLoading: !response.data && !response.error,
  }
}

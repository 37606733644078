import { composeFetch, RequestMiddleware } from '@library/http'
import {
  addPath,
  auth,
  composeRequest,
  setMethod,
  setQueryParams,
  setServiceUrl,
} from '@library/http/requests'
import { extractUserCookbookResponse } from './deserialisers'

type UserCookbookParams = {
  limit: number
  pageAfter: string
}

const userCookbookRequest: RequestMiddleware<UserCookbookParams> = composeRequest(
  setServiceUrl('cookbook', 1),
  addPath(() => `user/current/recipes`),
  setQueryParams(({ limit, pageAfter }: UserCookbookParams) => ({
    limit: limit.toString(),
    'page[after]': pageAfter,
  })),
  setMethod('GET'),
  auth,
)

export const userCookbook = composeFetch(userCookbookRequest, extractUserCookbookResponse)

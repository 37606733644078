import { PROTOCOL_PREFIX } from '@library/environment'
import Cookies from 'cookies-js'
import { getProtocol } from 'utils/isomorphicEnvironment'
import logger from 'utils/logger'

// Set default cookie options
Cookies.defaults = {
  ...Cookies.defaults,
  path: '/',
  secure: (getProtocol() === PROTOCOL_PREFIX.HTTPS),
}

// Gousto specific cookie consent logic
const DEFAULT_CONSENT_COOKIE_NAME = 'v1_gousto_cookie_consent'
const ANALYTICS = 'ANALYTICS'

const hasAcceptedAnalytics = (
) => {
  try {
    const consentCookieString = Cookies.get(DEFAULT_CONSENT_COOKIE_NAME)
    const consentCookie = consentCookieString ? JSON.parse(consentCookieString) : {}

    return consentCookie?.categories?.includes(ANALYTICS)
  } catch (error) {
    if (error instanceof Error) {
      logger.error(error.message)
    }

    return false
  }
}

export { hasAcceptedAnalytics }
// To avoid making further changes to the existing codebase:
// ToDo: Refactor Cookies to use named exports
// eslint-disable-next-line import/no-default-export
export default Cookies
